import { useState, useEffect, useRef, Suspense, FC, SyntheticEvent, ReactNode, useCallback } from 'react';
import { Box, Container, IconButton, useTheme, Skeleton, Button, NoSsr } from '@mui/material';
import loadable from '@loadable/component';
import { ChevronLeft } from 'react-feather';
import { useInView } from "react-intersection-observer";
import { motion, useScroll, useTransform } from 'framer-motion';
import { Capacitor } from '@capacitor/core';
import { PlayArrow } from '@mui/icons-material';
const ReactPlayer = loadable(() => import(/* webpackChunkName: "player" */'react-player/lazy'));

import { CanonicalContent, Image as ImageType, Season, Share, Video, WatchState as WatchStateType } from '../../generated/graphql';
import { useIsWatched, useIsInWatchlist, useToggleWatched, useToggleInWatchlist } from '../../hooks/watch-state';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useStatusBarColor } from '../../hooks/status-bar-color';
import { useLoginPush } from '../../hooks/login-push';
import { ScrollElementProvider } from '../../hooks/scroll-element';
import { useSession } from '../../hooks/auth';
import { useScrollUpDown } from '../../hooks/scroll-trigger';
import { createVideoUrl } from '../../utils/video';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Image } from '../../components/Image';
import { BackButton } from '../../components/BackButton';
import { FallbackCanonical } from '../../components/icons/FallbackCanonical';
import { Header, HeaderProps } from './Header';
import { WatchState } from './WatchState';
import { Divider } from './Divider';
import { RatingPopover } from '../../components/RatingPopover';
import { ScrollOnNavigation } from './ScrollOnNavigation';
import { WatchedSeasonsDialog } from '../../components/canonical/WatchedSeasonsDialog';


export type LayoutProps = {
  children?: ReactNode,
  headerTitle: string,
  canonical?: Pick<CanonicalContent, '_id' | 'type'> & {
    rating?: HeaderProps['rating'],
    tvshow?: {
      seasons: Array<Pick<Season, 'seasonNumber' | 'name' | 'airDate'>>,
    },
  } & HeaderProps['canonical'],
  share?: Pick<Share, '_id'> & {
    rating?: HeaderProps['rating'],
  } & HeaderProps['share'],
  backdrop?: Pick<ImageType, 'hash' | 'width' | 'height' | 'alt' | 'blurhash'>,
  poster?: Pick<ImageType, 'hash' | 'width' | 'height' | 'alt' | 'blurhash'>,
  title?: ReactNode,
  video?: Pick<Video, 'site' | 'key' | 'type'>
  loading?: boolean,
  watchedBy?: WatchStateType['watchedBy'],
  watchlistBy?: WatchStateType['watchlistBy'],
  isVisible: boolean,
}


export const Layout: FC<LayoutProps> = function Layout({ children, headerTitle, canonical, share, backdrop, poster, title, video, loading, watchedBy, watchlistBy, isVisible }) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();
  const { user } = useSession();
  const { setLoginHint } = useLoginPush();

  const [ratingPopoverOpen, setRatingPopoverOpen] = useState(false);

  const [watchedSeasonsDialogOpen, setWatchedSeasonsDialogOpen] = useState(false);

  const rating = canonical?.rating || share?.rating;

  const [showVideo, setShowVideo] = useState(false);

  const videoUrl = video ? createVideoUrl(video) : '';

  const pressPlay = () => {
    track('play_trailer', { category: 'canonical' });
    setShowVideo(true);
  };

  const isInWatchlist = useIsInWatchlist(share?._id, canonical?._id);
  const isWatched = useIsWatched(share?._id, canonical?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const { toggleWatched, toggleWatchedSeasons } = useToggleWatched();

  const handleWatchlist = useCallback((event: SyntheticEvent, active: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    if(user) {
      toggleInWatchlist(canonical?._id, share?._id, active);
    } else {
      track('click_toggle_watchlist', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to keep track of what you want to see.');
    }
  }, [user, canonical?._id, share?._id, toggleInWatchlist, setLoginHint, track]);

  const handleWatched = useCallback((event: SyntheticEvent, active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();
    if(user) {
      track('click_watched_button', { category: 'watch-state', origin: 'canonical' });
      // Show season popup if canonical is tvshow with multiple seasons
      const seasons = canonical?.tvshow?.seasons;
      if(canonical?.type === 'tvshow') {
        if(seasons && seasons.length === 1) {
          toggleWatchedSeasons(canonical?._id, [seasons[0].seasonNumber], active, createFeedItems);
        } else if (seasons && seasons.length > 1) {
          setWatchedSeasonsDialogOpen(true);
        } else {
          toggleWatched(canonical?._id, share?._id, active, createFeedItems);
        }
      } else {
        toggleWatched(canonical?._id, share?._id, active, createFeedItems);
      }

      if(active && !(seasons && seasons.length > 1) && !rating) {
        setRatingPopoverOpen(true);
      }
    } else {
      track('click_toggle_watched', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to keep track of what you have seen.');
    }
  }, [user, canonical, share, toggleWatched, toggleWatchedSeasons, setLoginHint, setRatingPopoverOpen, setWatchedSeasonsDialogOpen, rating, track]);

  const handleWatchedSeasons = useCallback((event: SyntheticEvent, seasonNumbers: number[], active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();
    if(!canonical?.tvshow) {
      console.error("Can't toggle watched seasons without tvshow", { canonicalId: canonical?._id, shareId: share?._id, seasonNumbers, active });
      throw new Error(`Can't toggle watched seasons without tvshow canonical`);
    }
    track('click_watched_season', { category: 'watch-state', origin: 'canonical', canonicalId: canonical?._id, shareId: active, seasonNumbers: seasonNumbers.join(',') });
    toggleWatchedSeasons(canonical._id, seasonNumbers, active, createFeedItems);
  }, [canonical, share, toggleWatchedSeasons, track]);

  // set bg-color for ios safe area
  useStatusBarColor({ color: theme.palette.background.paper, style: theme.palette.mode === 'dark' ? 'Dark' : 'Light' });
  const [ heroRef, heroInView ] = useInView({ threshold: 0.01, initialInView: false });

  // calc offset for initial position and header toggle limit
  const aboveHeaderRef = useRef<HTMLDivElement>();

  const [ scrollBelowHero, setScrollBelowHero ] = useState(false /*!!user*/); // no scroll below hero for non-logged in users
  const hasScrolled = useRef(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContentRef = useRef<HTMLElement | null>(null);

  useScrollUpDown(scrollContainerRef, isVisible);

  /*
  // Scroll down to header on mount
  useEffect(() => {
    if (scrollContainerRef.current && aboveHeaderRef.current && scrollContentRef.current && !hasScrolled.current) {
      // don't auto-scroll more than once
      hasScrolled.current = true;
      // make sure we have room for reposition
      if (user && scrollContentRef.current.clientHeight - window.innerHeight > aboveHeaderRef.current.offsetTop) {
        scrollContainerRef.current.scrollTo({ top: aboveHeaderRef.current.offsetTop + 1, behavior: 'instant' });
      } else {
        setScrollBelowHero(false);
      }
    }
  }, [ scrollContainerRef, aboveHeaderRef, scrollContentRef, user, share ]);
  */

  // Toggle header variant on scroll
  const { scrollY } = useScroll({ container: scrollContainerRef });
  useEffect(() => {
    if(!aboveHeaderRef.current) {
      return;
    }
    return scrollY.onChange((latest) => {
      aboveHeaderRef?.current?.offsetTop && setScrollBelowHero(latest >= aboveHeaderRef.current.offsetTop + 1);
      // don't auto-scroll after user interaction
      hasScrolled.current = true;
    });
  }, [ scrollY, setScrollBelowHero, aboveHeaderRef, loading ]);

  const handleClickAway = useCallback((ev: SyntheticEvent) => {
    setShowVideo(false);
    ev.stopPropagation();
    ev.preventDefault();
  }, [setShowVideo]);

  const imageScale = useTransform(scrollY, [0, 390], [1.0, 1.15]);
  const posterTranslateX = useTransform(scrollY, [100, 390], [0, -16]);
  const posterTranslateY = useTransform(scrollY, [100, 390], [0, 130]);
  const posterScale = useTransform(scrollY, [100, 390, 391], [1.0, 0.6, 0]);
  const textOpacity = useTransform(scrollY, [0, 150], [1.0, 0.0]);

  return(
    <ErrorBoundary>
      <Container ref={scrollContainerRef} maxWidth="sm" disableGutters={true} sx={{ overflowY: 'scroll', height: '100%', position: 'relative' }}>
        <ScrollElementProvider scrollContainerRef={scrollContainerRef} scrollContentRef={scrollContentRef}>
          <ScrollOnNavigation canonicalId={canonical?._id} shareId={share?._id} />

          {canonical?.tvshow?.seasons &&
            <WatchedSeasonsDialog canonicalId={canonical._id} open={watchedSeasonsDialogOpen} onClose={() => setWatchedSeasonsDialogOpen(false)} handleWatchedSeason={handleWatchedSeasons} seasons={canonical?.tvshow?.seasons} />
          }

          {Capacitor.getPlatform() === 'ios' &&
            <Divider sx={{ position: 'sticky', zIndex: 5, top: 0, width: '100%', borderColor:  heroInView ? 'grey.400' : 'grey.50', transition : 'border 200ms ease-out' }} />
          }
          <Box ref={scrollContentRef} sx={{ top: 300, pb: 12, backgroundColor: 'grey.50', minHeight: '100%' }}>

            <Box sx={{ position: 'relative', }}>
              <Box ref={heroRef} sx={{
                width: '100%',
                aspectRatio: '375 / 292',
                position: 'relative',
                overflow: 'clip',

                paddingTop: (100 / (375 / 292)) + '%', // fallback for aspectRatio
              }}>

                <Box sx={{
                  position: 'absolute',
                  zIndex: 0,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}>
                  <motion.div style={{scale: imageScale, transformOrigin: 'top right' }}>
                    {!backdrop && loading &&
                      <Skeleton variant="rectangular" width='100%' height='100%' />
                    }
                    {backdrop &&
                      <Image
                        {...backdrop}
                        width={375}
                        height={292}
                        constraints='375x292_c'
                        loading='eager'
                        preload={true}
                      />
                    }
                    {!backdrop &&
                      <FallbackCanonical sprinkleColor={theme.palette.primary.light} sx={{ width: '100%', height: '100%' }} />
                    }
                  </motion.div>
                </Box>

                <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)', zIndex: 1 }}></Box>

                <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 4, height: '56px', display: 'flex' }}>
                  <BackButton component={IconButton} sx={{  }}>
                    <ChevronLeft style={{ strokeWidth: 3, strokeLinecap: 'square', height: '30px', width: '30px', color: 'white' }} />
                  </BackButton>
                </Box>

                <Box sx={{
                  position: 'absolute',
                  bottom: '12px',
                  pl: loading || poster ? '124px' : '16px',
                  pr: '16px',
                  zIndex: 2,
                  width: '100%',
                }}>
                  <motion.div style={{ opacity: textOpacity }}>
                    {title}
                  </motion.div>
                </Box>

                <WatchState
                  watchlistBy={watchlistBy || []}
                  isInWatchlist={isInWatchlist}
                  watchedBy={watchedBy || []}
                  isWatched={isWatched}
                />

                {video &&
                  <>
                    <Box sx={{ visibility: showVideo ? 'visible' : 'hidden', position: 'absolute', zIndex: 5, top: 0, left: 0, width: '100%', height: '100%', }}>
                      <NoSsr>
                        <Suspense fallback={null}>
                          {showVideo &&
                            <ReactPlayer url={videoUrl} playing width='100%' height='100%' controls={false} playsinline={true} style={{ zIndex: theme.zIndex.videoPlayer }} onClick={(ev: SyntheticEvent) => ev.stopPropagation()} config={{ youtube: { playerVars: { vq: 'tiny', modestbranding: 1 }, embedOptions: { host: 'https://www.youtube-nocookie.com' } } }} />
                          }
                        </Suspense>
                      </NoSsr>
                    </Box>

                    <Box sx={{
                      display: 'flex',
                      position: 'absolute',
                      bottom: '12px',
                      right: 16,
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 3,
                    }}>
                      <motion.div style={{ opacity: textOpacity }}>
                        <Button variant='outlined' onClick={pressPlay} sx={{ color: '#ddd', backgroundColor: 'rgba(255, 255, 255, 30%)', borderColor: 'transparent', borderRadius: '999px', height: '24px', typography: 'h4', fontWeight: 400, px: '7px' }} color='primaryInverted' size='small'>
                          {video.type || 'Trailer'}
                          <PlayArrow sx={{ color: 'inherit', width: 16, height: 16, ml: '2px' }} />
                        </Button>
                      </motion.div>
                    </Box>
                  </>
                }

                {!poster && loading &&
                  <Box sx={{
                    position: 'absolute',
                    bottom: '12px',
                    left: 16,
                    width: 92,
                    height: 138,
                    boxShadow: theme.shadows[2],
                    zIndex: 2,
                  }}>
                    <Skeleton variant="rectangular" width='100%' height='100%' />
                  </Box>
                }

                {poster &&
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '12px',
                      left: 16,
                      width: 92,
                      height: 138,
                      boxShadow: theme.shadows[2],
                      zIndex: 2,
                      filter: showVideo ? 'blur(5px)' : 'none',
                    }}
                  >
                    <motion.div style={{translateY: posterTranslateY, translateX: posterTranslateX, scale: posterScale}}>
                      <Image
                        {...poster}
                        constraints='184x276_c'
                        width={184} height={276}
                      />
                      {showVideo &&
                        <Box sx={{ position: 'absolute', inset: 0, zIndex: theme.zIndex.videoPlayer - 1 }} onClick={handleClickAway}></Box>
                      }
                    </motion.div>
                  </Box>
                }
              </Box>
            </Box>
            <Box ref={aboveHeaderRef} sx={{ filter: showVideo ? 'blur(5px)' : 'none' }}>
              <Header
                title={headerTitle}
                image={poster || backdrop}
                share={share}
                canonical={canonical}
                isWatched={isWatched}
                handleWatched={handleWatched}
                isInWatchlist={isInWatchlist}
                handleWatchlist={handleWatchlist}
                variant={scrollBelowHero ? 'small' : 'large'}
                setRatingPopoverOpen={setRatingPopoverOpen}
                rating={rating || null}
              />

              {children}

              {showVideo &&
                <Box sx={{ position: 'absolute', inset: 0, zIndex: theme.zIndex.videoPlayer - 1 }} onClick={handleClickAway}></Box>
              }
            </Box>
          </Box>
        </ScrollElementProvider>
      </Container>

      {(canonical?._id || share?._id) &&
        <RatingPopover canonicalContentId={canonical?._id} shareId={share?._id} isWatched={isWatched} open={ratingPopoverOpen} onClose={() => setRatingPopoverOpen(false)} rating={rating || undefined} handleWatched={handleWatched} />
      }
    </ErrorBoundary>
  );
}
