import { FC, SyntheticEvent, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BooleanParam, useQueryParam } from "use-query-params";
import { Avatar as MuiAvatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography, useTheme } from "@mui/material";
import { ChevronRight } from 'react-feather';

import { useIsVisible } from '@/hooks/use-is-visible';
import { useSession } from "../../hooks/auth";
import { useNavigationActions } from '../../hooks/history';
import { BottomSheet } from "../../components/BottomSheet";
import { AvatarGroup } from "../../components/AvatarGroup";
import { UserListItem } from "./UserListItem";
import { Button } from '../../components/Button';
import { AvatarList } from '@/components/AvatarList';


export const WatchedBy: FC<{ watchedBy: string[], isWatched: number }> = function WatchedBy({ watchedBy, isWatched }) {

  const { user } = useSession();
  const history = useHistory();
  const isVisible = useIsVisible();

  const { closeSheet } = useNavigationActions();
  const sheetQueryParam = "watched-by-sheet";
  const [open, setOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const watchedByWithSelf = useMemo(() => {
    const arr = watchedBy.filter(uid => uid !== user?._id);
    if(isWatched) {
      arr.push(user?._id);
    }
    return arr;
  }, [watchedBy, user?._id, isWatched]);

  const onClose = useCallback((ev?: SyntheticEvent) => {
    ev?.stopPropagation();
    closeSheet(sheetQueryParam);
  }, [closeSheet, sheetQueryParam]);

  const handleLogin = useCallback(() => {
    history.push(`/auth/login?next=${encodeURIComponent(window.location.pathname+window.location.search)}`);
  }, [history]);

  return (
    <WatchedByUI
      watchedBy={watchedByWithSelf}
      isLoggedIn={!!user}
      onClose={onClose}
      handleLogin={handleLogin}
      open={!!open && isVisible}
      setOpen={setOpen}
    />
  );
}



type WatchedByUIProps = {
  watchedBy: string[],
  isLoggedIn: boolean,
  onClose: (ev?: SyntheticEvent) => void,
  handleLogin: () => void,
  open: boolean,
  setOpen: (open: boolean) => void,
}


const WatchedByUI: FC<WatchedByUIProps> = memo(function WatchedByUI({ watchedBy, isLoggedIn, onClose, handleLogin, open, setOpen }: WatchedByUIProps) {

  const theme = useTheme();

  return (
    <>
      <ListItemButton disableGutters onClick={() => setOpen(true)}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ flex: '0 0 auto', color: 'text.primary' }}>
            Watched by
          </Typography>
          <Box sx={{ pl: 3, flex: '1 1 auto', textAlign: 'right' }}>
            {isLoggedIn &&
              <AvatarList max={5} contacts={watchedBy} size="small" sx={{ '&&.MuiAvatarGroup-root .MuiAvatar-root': { borderColor: 'grey.100' } }} />
            }
            {!isLoggedIn &&
              <AvatarGroup max={5} size='small' sx={{ '&&.MuiAvatarGroup-root .MuiAvatar-root': { borderColor: 'grey.100' } }}>
                {[0, 1].map(i =>
                  <MuiAvatar
                    key={i}
                    sx={{
                      color: 'text.primary',
                      fontWeight: 500,
                      backgroundColor: 'grey.300',
                      borderWidth: '1.5px',
                      fontSize: '12px',

                      width: '20px',
                      height: '20px',
                      borderStyle: 'none',
                      '&&&': {
                        borderWidth: '1.5px',
                      },
                    }}
                  />
                )}
              </AvatarGroup>
            }
          </Box>
          <Box sx={{ flex: '0 0 auto', pl: 1, lineHeight: 0 }}>
            <ChevronRight style={{ color: theme.palette.text.secondary }} />
          </Box>
        </Box>
      </ListItemButton>

      {open &&
        <BottomSheet open={!!open} onClose={onClose} onOpen={() => null}>
          <List>
            <ListItem sx={{ px: 0, pb: 1 }}>
              <Typography variant="h2">
                Watched by
              </Typography>
            </ListItem>
            {open &&
              <>
                {!isLoggedIn &&
                  <>
                    {[0, 1].map(i => (
                      <ListItem sx={{ m:0, p:0 }} key={i}>
                        <ListItemAvatar>
                          <MuiAvatar
                            sx={{
                              color: 'text.primary',
                              fontWeight: 500,
                              backgroundColor: 'grey.300',
                              borderWidth: '2px',
                              fontSize: '20px',

                              width: '40px',
                              height: '40px',
                              borderStyle: 'none',
                              '&&&': {
                                borderWidth: '2px',
                              },
                              mr: 1,
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Skeleton variant="text" width={130} animation={false} />}
                          secondary={<Skeleton variant="text" width={60} animation={false} />}
                        />
                      </ListItem>
                    ))}
                    <ListItem sx={{ px: 0 }}>
                      <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        Log in to see which of your friends that have watched this.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <Button variant="contained" size='small' fullWidth onClick={handleLogin}>Log in</Button>
                    </ListItem>
                  </>
                }
                {isLoggedIn && watchedBy.length === 0 &&
                  <ListItem sx={{ px: 0 }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Be the first among your friends to see this.
                    </Typography>
                  </ListItem>
                }
                {isLoggedIn && watchedBy.map(uid =>
                  <UserListItem userId={uid} key={uid} />
                )}
              </>
            }
          </List>
        </BottomSheet>
      }

    </>
  )
});
