import { FC, MouseEvent, ReactNode } from 'react';
import { Box, ListItem, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import { Image, ImageProps } from '../Image';
import { CheckCircle, CheckCircleFilled, AddCircle, AddCircleFilled } from '../icons';
import { CheckCircleHalf } from '../icons/CheckCircleHalf';


export type HeaderUnfurlProps = {
  title: string | null,
  description: string | ReactNode,
  image?: Pick<ImageProps, 'alt' | 'hash' | 'blurhash'>,
  isWatched: number,
  handleWatched: (ev: MouseEvent, watched: boolean) => void,
  isInWatchlist: boolean,
  handleWatchlist: (ev: MouseEvent, inWatchlist: boolean) => void,
};


export const HeaderUnfurl: FC<HeaderUnfurlProps> = function HeaderUnfurl({ title, description, image, isWatched, isInWatchlist, handleWatched, handleWatchlist }) {

  const motionConf = {
    initial: { y: -30, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 30, opacity: 0 },
  };

  return (
    <ListItem sx={{ mx: 2, my: 1, flexDirection: 'column', alignItems: 'start', p: 0 }} onClick={() => null}>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: 48 }}>
        {image &&
          <Box sx={{ width: 48, height: 48, mr: 1.5, borderRadius: '4px', overflow: 'hidden', flex: '0 0 auto' }}>
            <Image constraints='72x72_c' {...image} width={72} height={72} />
          </Box>
        }
        <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>

          <Typography variant="h3" sx={{ color: isWatched ? 'text.secondary' : 'text.primary', fontStyle: title ? 'normal' : 'italic' }} noWrap>
            {title || 'Title missing'}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {description}
          </Typography>

        </Box>

        <Box sx={{ flex: '1 1 auto', display: 'flex', mr: 2, pl: 1, pr: 2, color: 'text.primary', justifyContent: 'flex-end', gap: 1 }} onClick={ () => null }>
          <AnimatePresence initial={true}>
            <Box key="watchlist" onClick={ev => handleWatchlist(ev, !isInWatchlist)} sx={{ lineHeight: 0 }}>
              {isInWatchlist ? (
                <motion.div key="watchlistfilled" {...motionConf}>
                  <AddCircleFilled sx={{ height: 24, width: 24, mr: 0.5, color: 'primary.main' }} />
                </motion.div>
              ) : (
                <motion.div key="watchlistoutlined" {...motionConf}>
                  <AddCircle sx={{ height: 24, width: 24, mr: 0.5, color: 'text.primary' }} />
                </motion.div>
              )}
            </Box>
            <Box key="watched" onClick={ev => handleWatched(ev, !isWatched)} sx={{ lineHeight: 0 }}>
              {isWatched === 1 &&
                <motion.div key="watchedfilled" {...motionConf}>
                  <CheckCircleFilled sx={{ height: 24, width: 24, mr: 0.5, color: 'primary.main' }} />
                </motion.div>
              }
              {isWatched > 0 && isWatched < 1 &&
                <motion.div key="watchedoutlined" {...motionConf}>
                  <CheckCircleHalf sx={{ height: 24, width: 24, mr: 0.5, color: 'primary.main' }} />
                </motion.div>
              }
              {isWatched === 0 &&
                <motion.div key="watchedoutlined" {...motionConf}>
                  <CheckCircle sx={{ height: 24, width: 24, mr: 0.5, color: 'text.primary' }} />
                </motion.div>
              }
            </Box>
          </AnimatePresence>
        </Box>
      </Box>
    </ListItem>
  );
}

