import { FC } from 'react';
import { Box, Card, Skeleton } from '@mui/material';


export const PostLoading: FC = function PostLoading() {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}> {/* prevent safari from collapsing margins causing issues with offsetHeight/clientHeight and lazyload */}
      <Box sx={{ mb: 2 }}>
        <Box sx={{ m: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, overflow: 'hidden' }}>
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="text" sx={{ width: 150 }} />
          </Box>
        </Box>

        <Card sx={{ borderRadius: '4px', mx: 2, py: 0 }}>

          <Skeleton variant="text" />
          <Skeleton variant="text" sx={{ width: '80%', mb: 1 }} />

          <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 64, borderRadius: 1, width: '85%' }}>

            <Skeleton variant="rectangular" width={64} height={64} />

            <Box
              sx={{
                px: 1,
                py: 1,
                flex: '1 1 auto',
                overflow: 'hidden',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'grey.100',
              }}
            >
              <Skeleton variant="text" sx={{ width: '50%' }} />
              <Skeleton variant="text" sx={{ width: '50%' }} />
            </Box>
          </Card>

          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', gap: 2 }}>
            <Skeleton variant='rectangular' sx={{ height: 32, flex: '1', borderRadius: 1, backgroundColor: 'grey.50' }} />
            <Skeleton variant='rectangular' sx={{ height: 32, flex: '1', borderRadius: 1, backgroundColor: 'grey.50' }} />
          </Box>
        </Card>

      </Box>
    </Box>
  );
}
