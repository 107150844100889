import { FC, MouseEvent } from 'react';

import { DepressButton } from '../../components/DepressButton';
import { Watched } from '../../components/icons/Watched';


export type WatchedButtonProps = {
  isWatched: number,
  handleWatched: (ev: MouseEvent, isWatched: boolean) => void,
  sx?: object,
};


export const WatchedButton: FC<WatchedButtonProps> = function WatchedButton({ isWatched, handleWatched, sx={}, ...rest }) {

  return (
    <DepressButton
      isDepressed={isWatched !== 0}
      color='offWhite'
      onClick={(ev: MouseEvent) => handleWatched(ev, !isWatched)}
      sx={{
        color: 'text.primary',
        backgroundColor: 'grey.200',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
        borderRadius: 0.5,
        fontWeight: 400,
        ...sx,
      }}
      startIcon={<Watched isWatched={isWatched} sx={{ width: 16, height: 16 }} />}
      {...rest}
    >
      <span>Seen</span>
    </DepressButton>
  );
}
