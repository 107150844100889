import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { Divider } from "./Divider";


export const Overview: FC<{ overview?: string | null }> = function Overview({ overview }) {
  if(!overview) return null;

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant='h3' sx={{ mb: 1 }}>Overview</Typography>
        <Typography
          variant='body1'
          sx={{
            // color: 'text.secondary',
            // display: '-webkit-box',
            // WebkitBoxOrient: 'vertical',
            // WebkitLineClamp: 5,
            // lineClamp: 5,
            // textOverflow: 'ellipsis',
            // overflow: 'hidden',
          }}
        >
          {overview}
        </Typography>
      </Box>
      <Divider />
    </>
  );
}
