import { FC } from "react";
import { Divider as MuiDivider, DividerProps as MuiDividerProps, SxProps, useTheme } from "@mui/material";


export type DividerProps = {
  sx?: SxProps;
} & Omit<MuiDividerProps, 'sx'>;


export const Divider: FC<DividerProps> = function Divider({ sx, ...props }) {
  const theme = useTheme();

  return (
    <MuiDivider {...props} sx={{ width: 'calc(100% - 24px)', borderColor: theme.palette.grey[300], margin: '0 auto', ...sx }} />
  );
}
