import { FC, memo } from 'react';
import { Box, ButtonGroup } from "@mui/material";
import { AnimatePresence, motion } from 'framer-motion';

import { Share } from '../../generated/graphql';
import { WatchlistButton, WatchlistButtonProps } from './WatchlistButton';
import { WatchedButton, WatchedButtonProps } from './WatchedButton';
import { OptionsButton, OptionsButtonProps } from './OptionsButton';
import { HeaderUnfurl, HeaderUnfurlProps } from '../../components/unfurl/HeaderUnfurl';
import { TypeYear } from '../../components/canonical/TypeYear';
import { RateButton, RateButtonProps } from './RateButton';


export type HeaderProps = {
  title: string,
  image: HeaderUnfurlProps['image'],
  canonical?: OptionsButtonProps['canonical'],
  share?: Pick<Share, '_id'> & OptionsButtonProps['share'],
  isWatched: WatchedButtonProps['isWatched'],
  handleWatched: WatchedButtonProps['handleWatched'],
  isInWatchlist: WatchlistButtonProps['isInWatchlist'],
  handleWatchlist: HeaderUnfurlProps['handleWatchlist'],
  variant: 'large' | 'small',
  setRatingPopoverOpen: RateButtonProps['setRatingPopoverOpen'],
  rating: RateButtonProps['rating'],
}


export const Header: FC<HeaderProps> = memo(function Header({ title, image, canonical, share, isWatched, handleWatched, isInWatchlist, handleWatchlist, variant, setRatingPopoverOpen, rating }: HeaderProps) {

  return (
    <Box sx={{
      overflow: 'hidden',
      position: 'sticky',
      top: 0,
      zIndex: 5,
      height: '64px',
      width: '100%',
      backgroundColor: 'background.paper',
      borderBottom: '1px solid',
      borderColor: 'grey.300',
    }}>
      <AnimatePresence initial={false}>
        {variant === 'large' &&
          <motion.div
            key="large"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, ease: 'linear' }}
            style={{ position: 'absolute', width: '100%' }}
          >
            <Box sx={{ mx: 2, my: 1.5, display: 'flex', gap: 1 }}>
              <WatchlistButton canonicalId={canonical?._id} shareId={share?._id} isInWatchlist={isInWatchlist} sx={{ flex: '1 1 auto' }} />
              <ButtonGroup
                size="small"
                disableRipple
                disableElevation
                sx={{
                  flex: '1 1 auto',
                  '& .MuiButtonGroup-grouped:not(:last-of-type):not(:first-of-type)': {
                    clipPath: 'inset(-5px -1px -5px -1px)'
                  },
                  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                    clipPath: 'inset(-5px -1px -5px -5px)'
                  },
                  '& .MuiButtonGroup-grouped:not(:first-of-type)': {
                    clipPath: 'inset(-5px -5px -5px -1px)'
                  },
                }}
              >
                <WatchedButton isWatched={isWatched} handleWatched={handleWatched} sx={{ flex: '1 1 auto' }} />
                <RateButton rating={rating} setRatingPopoverOpen={setRatingPopoverOpen} sx={{ flex: '1 1 auto' }} />
              </ButtonGroup>

              <OptionsButton canonical={canonical} share={share} sx={{ flex: '0 0 auto', mr: -1 }} />
            </Box>
          </motion.div>
        }
        {variant === 'small' &&
          <motion.div
            key="small"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, ease: 'linear' }}
            style={{ position: 'absolute', width: '100%' }}
          >
            <HeaderUnfurl title={title} description={canonical ? <TypeYear canonical={canonical}/> : ''} image={image} isWatched={isWatched} handleWatched={handleWatched} isInWatchlist={isInWatchlist} handleWatchlist={handleWatchlist} />
          </motion.div>
        }
      </AnimatePresence>
    </Box>
  );
});

