import { FC, SyntheticEvent, memo, useCallback, useMemo } from 'react';
import { BooleanParam, useQueryParam } from "use-query-params";
import { Avatar as MuiAvatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography, useTheme } from "@mui/material";
import { ChevronRight } from 'react-feather';
import { useHistory } from 'react-router-dom';

import { useIsVisible } from '@/hooks/use-is-visible';
import { useSession } from "../../hooks/auth";
import { useNavigationActions } from '../../hooks/history';
import { BottomSheet } from "../../components/BottomSheet";
import { AvatarGroup } from "../../components/AvatarGroup";
import { UserListItem } from "./UserListItem";
import { Button } from '../../components/Button';
import { AvatarList } from '@/components/AvatarList';


type AddedToWatchlistByUIProps = {
  addedToWatchlistBy: string[],
  isLoggedIn: boolean,
  onClose: (ev?: SyntheticEvent) => void,
  handleLogin: () => void,
  open: boolean,
  setOpen: (open: boolean) => void,
}

const AddedToWatchlistByUI: FC<AddedToWatchlistByUIProps> = memo(function AddedToWatchlistByUI({ addedToWatchlistBy, isLoggedIn, onClose, handleLogin, open, setOpen }: AddedToWatchlistByUIProps) {

  const theme = useTheme();

  return (
    <>
      <ListItemButton disableGutters onClick={() => setOpen(true)}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ flex: '0 0 auto', color: 'text.primary' }}>
            In Watchlist
          </Typography>
          <Box sx={{ pl: 3, flex: '1 1 auto', textAlign: 'right' }}>
            {isLoggedIn &&
              <AvatarList max={5} contacts={addedToWatchlistBy} size="small" sx={{ '&&.MuiAvatarGroup-root .MuiAvatar-root': { borderColor: 'grey.100' } }} />
            }
            {!isLoggedIn &&
              <AvatarGroup max={5} size='small' sx={{ '&&.MuiAvatarGroup-root .MuiAvatar-root': { borderColor: 'grey.100' } }}>
                {[0].map(i =>
                  <MuiAvatar
                    key={i}
                    sx={{
                      color: 'text.primary',
                      fontWeight: 500,
                      backgroundColor: 'grey.300',
                      borderWidth: '1.5px',
                      fontSize: '12px',

                      width: '20px',
                      height: '20px',
                      borderStyle: 'none',
                      '&&&': {
                        borderWidth: '1.5px',
                      },
                    }}
                  />
                )}
              </AvatarGroup>
            }
          </Box>
          <Box sx={{ flex: '0 0 auto', pl: 1, lineHeight: 0 }}>
            <ChevronRight style={{ color: theme.palette.text.secondary }} />
          </Box>
        </Box>
      </ListItemButton>

      {open &&
        <BottomSheet open={!!open} onClose={onClose} onOpen={() => null}>
          <List>
            <ListItem sx={{ px: 0, pb: 1 }}>
              <Typography variant="h2">
                Added to Watchlist by
              </Typography>
            </ListItem>
            {open &&
              <>
                {!isLoggedIn &&
                  <>
                    {[0].map(i => (
                      <ListItem sx={{ m:0, p:0 }} key={i}>
                        <ListItemAvatar>
                          <MuiAvatar
                            sx={{
                              color: 'text.primary',
                              fontWeight: 500,
                              backgroundColor: 'grey.300',
                              borderWidth: '2px',
                              fontSize: '20px',

                              width: '40px',
                              height: '40px',
                              borderStyle: 'none',
                              '&&&': {
                                borderWidth: '2px',
                              },
                              mr: 1,
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Skeleton variant="text" width={130} animation={false} />}
                          secondary={<Skeleton variant="text" width={60} animation={false} />}
                        />
                      </ListItem>
                    ))}
                    <ListItem sx={{ px: 0 }}>
                      <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        Log in to see which of your friends that have saved this to their watchlist.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <Button variant="contained" size='small' fullWidth onClick={handleLogin}>Log in</Button>
                    </ListItem>
                  </>
                }
                {isLoggedIn && addedToWatchlistBy.length === 0 &&
                  <ListItem sx={{ px: 0 }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Be the first among your friends to see this.
                    </Typography>
                  </ListItem>
                }
                {isLoggedIn && addedToWatchlistBy.map(uid =>
                  <UserListItem userId={uid} key={uid} />
                )}
              </>
            }
          </List>
        </BottomSheet>
      }

    </>
  );
});


export type AddedToWatchlistByProps = {
  addedToWatchlistBy: string[],
  isInWatchlist: boolean,
}

export const AddedToWatchlistBy: FC<AddedToWatchlistByProps> = function AddedToWatchlistBy({ addedToWatchlistBy, isInWatchlist }) {

  const history = useHistory();
  const { user } = useSession();
  const isVisible = useIsVisible();

  const { closeSheet } = useNavigationActions();
  const sheetQueryParam = "added-to-watchlist-by-sheet";
  const [open, setOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const addedToWatchlistByWithSelf = useMemo(() => {
    const arr = addedToWatchlistBy.filter(uid => uid !== user?._id);
    if(isInWatchlist) {
      arr.push(user?._id);
    }
    return arr;
  }, [addedToWatchlistBy, isInWatchlist, user?._id]);

  const onClose = useCallback((ev?: SyntheticEvent) => {
    ev?.stopPropagation();
    closeSheet(sheetQueryParam);
  }, [closeSheet, sheetQueryParam]);

  const handleLogin = useCallback(() => {
    history.push(`/auth/login?next=${encodeURIComponent(window.location.pathname+window.location.search)}`);
  }, [history]);

  return (
    <AddedToWatchlistByUI
      addedToWatchlistBy={addedToWatchlistByWithSelf}
      isLoggedIn={!!user}
      onClose={onClose}
      handleLogin={handleLogin}
      open={!!open && isVisible}
      setOpen={setOpen}
    />
  );
}
