import { FC, memo, useCallback, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import { CanonicalContent, Contact, Context, Group, Message, Rating, Share, Unfurl } from '../../generated/graphql';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useIsImpression } from '../../hooks/is-impression';
import { useLoginPush } from '../../hooks/login-push';
import { useSession } from '../../hooks/auth';
import { absolutize, domain, parseInternal, testHasSameContent, testYoutube } from '../../utils/url';
import { Comments, CommentsProps } from '../../components/Post/Comments';
import { Divider } from './Divider';
import { AutoplayingVideo } from '../../components/AutoplayingVideo';
import { MessageCard, MessageCardProps } from '../../components/Post/MessageCard';
import { Header as PostHeader, HeaderProps as PostHeaderProps } from '../../components/Post/Header';
import { Status } from '../../components/Status';


export type PostProps = {
  share: Pick<Share, '_id' | 'commentCount'> & {
    context?: Pick<Context, 'type'> & {
      group?: Pick<Group, '_id' | 'name' | 'color'> | null,
    } | null,
    message?: Pick<Message, 'link'> | null,
    reshareOf?: Pick<Share, '_id'> & {
      message?: {
        link?: string | null,
      },
    } | null,
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
    unfurl: Pick<Unfurl, 'link' | 'title' | 'images'>,
    list?: MessageCardProps['list'] | null,
    comments: CommentsProps['comments'],
  } & CommentsProps['share'] & MessageCardProps['share'] & PostHeaderProps['share'];
  canonical?: Pick<CanonicalContent, '_id'> & MessageCardProps['canonical'] | null,
  hlComment?: string;
  showAllComments?: boolean;
}


export const Post: FC<PostProps> = memo(function Post({ share, canonical, hlComment, showAllComments }: PostProps) {

  const { user } = useSession();

  const theme = useTheme();
  const location = useLocation();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();


  const [ impressionRef, isImpression ] = useIsImpression({ duration: 600 });

  const [showCommentView, _setShowCommentView] = useQueryParam('showCommentView', StringParam);
  const setShowCommentView = useCallback((value: string) => {
    if(user) {
      if(value) {
        track('show_comments_view', { category: 'canonical' });
      } else {
        track('hide_comments_view', { category: 'canonical' });
      }
      _setShowCommentView(value);
    } else {
      track('click_show_comments', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to join the conversation.');
    }
  }, [ _setShowCommentView, track, user, setLoginHint ]);

  useEffect(() => {
    if (isImpression) {
      track('feed_item_impression', { category: 'group', feed_item_imp: 1 });
    }
  }, [isImpression, track]);

  if(!share) {
    throw new Error('Post/share data missing');
  }

  let contextColor = theme.palette.groups.direct;
  let groupColor = null;
  if(share.context?.group?.color && share.context.group.color in theme.palette.groups) {
    const c = share.context.group.color as keyof typeof theme.palette.groups;
    groupColor = theme.palette.groups[c];
  }
  if(share.context?.group?.name !== '' && groupColor) {
    contextColor = groupColor;
  }
  if(share.context?.type && ['public', 'public-list'].includes(share.context.type)) {
    contextColor = theme.palette.groups.public;
  }

  const link = share.message?.link ? parseInternal(share.message.link) : null;
  const isImdbLink = share.unfurl?.link && ['imdb.com', 'm.imdb.com'].includes(domain(share.unfurl.link));
  const isSameTarget = share.unfurl?.link && link?.isInternal && testHasSameContent(share.unfurl.link, absolutize(location.pathname));
  const hideUnfurl = Boolean(!share.unfurl?.link || link?.isCollection || isSameTarget || isImdbLink);

  const list = (link?.isCollection || share.context?.type === 'public-list') ? share.list : undefined;

  const shareVideoLink = share.message?.link && testYoutube(share.message.link) && share.message.link;
  const reshareVideoLink = share.reshareOf?.message?.link && testYoutube(share.reshareOf.message.link) && share.reshareOf.message.link;
  const video = shareVideoLink || reshareVideoLink;

  return (
    <Box component="article" sx={{ display: 'flex', flexDirection: 'column' }}> {/* prevent safari from collapsing margins causing issues with offsetHeight/clientHeight and lazyload */}
      <Box sx={{ mb: 2 }} data-cy='canonical-share'>
        {share.deleted &&
          <Status code={404} />
        }
        <PostHeader share={share} />

        {video &&
          <AutoplayingVideo url={video} sx={{ backgroundColor: 'grey.100', ml: 2, mt: 0, mr: 2, mb: 1 }} onClick={undefined} />
        }

        <MessageCard
          share={share}
          canonical={canonical}
          list={list}
          hideUnfurl={hideUnfurl}
          impressionRef={impressionRef}
          setShowCommentView={setShowCommentView}
        />

        {share.comments &&
          <>
            {share.commentCount > 0 &&
              <Divider sx={{ width: '100%', pt: 0.5, mb: 0.5, borderColor: 'grey.100' }}/>
            }
            <Comments
              share={share}
              comments={share.comments}
              numberOfExpandedComments={showAllComments ? Infinity : 3}
              contextColor={contextColor}
              showCommentView={showCommentView || undefined}
              setShowCommentView={setShowCommentView}
              hideAddComment={share.comments?.length > 0 ? false : true}
            />
          </>
        }

      </Box>
    </Box>
  );
});
