import { Skeleton, Box, List, Container } from '@mui/material';
import { Divider } from './Divider';

export function Loading() {

  return (
    <Container maxWidth="sm" disableGutters={true} sx={{ height: '100%', position: 'relative' }}>
      <Box sx={{ width: "100%", height: 'initial', aspectRatio: '375 / 292', position: 'relative' }}>
        <Skeleton variant="rectangular" sx={{ width: "100%", height: '100%' }} />
        <Skeleton variant='rectangular' sx={{ width: 92, height: 138, position: 'absolute', bottom: 16, left: 16 }} />

        <Box sx={{ position: 'absolute', bottom: 16, pl: '124px', pr: 2, width: '100%' }}>
          <Skeleton variant='text' sx={{ fontSize: 30, width: '100%' }} />
          <Skeleton variant='text' sx={{ fontSize: 20, width: 180 }} />
          <Skeleton variant='text' sx={{ fontSize: 16, width: 60 }} />
        </Box>
      </Box>

      <Box sx={{ mx: 2, my: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <Skeleton variant="rounded" sx={{ flex: '1 1 143px', height: 40, }} />
        <Skeleton variant="rounded" sx={{ flex: '1 1 175px', height: 40, }} />
        <Skeleton variant="text" sx={{ flex: '0 0 32px' }} />
      </Box>

      <Divider />

      <List sx={{ mx: 3 }} disablePadding>
        <Skeleton height={200} />
        <Skeleton height={80} />
        <Skeleton height={80} />
      </List>


    </Container>
  )
}
