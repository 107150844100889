import { FC, SyntheticEvent, useCallback, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ChevronRight } from "react-feather";

import { CanonicalContent, useCanonicalSponsorQuery } from "../../generated/graphql";
import { Divider } from "./Divider";
import { Button } from "../../components/Button";
import { User } from "../../components/User";
import { DecoratedText } from "../../components/DecoratedText";
import { AdTracker } from "@/components/AdTracker";
import { useOpenLink } from "@/hooks/link";


export type SponsoredProps = {
  canonical: Pick<CanonicalContent, '_id'>
}


export const Sponsored: FC<SponsoredProps> = function({ canonical }) {

  const { data, error } = useCanonicalSponsorQuery({
    skip: !canonical,
    variables: {
      canonicalContentId: canonical._id,
      country: 'SE',
      language: 'sv',
    }
  });

  const ad = data?.canonicalSponsor;

  const [clickCounter, setClickCounter] = useState(0);
  const { openLink } = useOpenLink(ad?.link || '');

  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setClickCounter((clickCounter) => clickCounter + 1);
    openLink();
  }, [openLink]);

  if(error) {
    console.error(error);
  }

  if(!ad || !ad.body || !ad.link) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Stack sx={{ px: 2, py: 1.5 }}>
        <Stack direction='row' sx={{ alignItems: 'center', gap: 1 }}>
          <User userId={ad.account?._id} size='medium' />
          <Typography variant='body2' sx={{ color: 'text.secondary', mt: '1px' }}>
            Sponsored
          </Typography>
        </Stack>

        <Box onClick={handleClick}>
          <AdTracker type='canonical' position='post' id={ad._id} account={ad.account?._id} campaign={ad.campaign} trackingPixel={ad.trackingPixel || undefined} clickCounter={clickCounter}>
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
                overflowWrap: 'break-word',
                mt: 1,
              }}
            >
              <DecoratedText text={ad.body} links={[]} mentions={[]} link={false} />
            </Box>

            <Button fullWidth variant="contained" sx={{ backgroundColor: '#'+ad.color, color: 'white', mt: 1 }}>
              {ad.buttonText} <ChevronRight style={{ marginLeft: '8px' }} />
            </Button>
          </AdTracker>
        </Box>

      </Stack>
      <Divider />
    </Box>
  );
}
