import { FC, SyntheticEvent } from "react";
import { Box, Card, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { CanonicalContent, Contact, Message, Rating, Share } from "@/generated/graphql";
import { useAnalyticsQueued } from "@/hooks/delicious-analytics";
import { canonicalLink } from "@/utils/routes";
import { RatingIcon } from "@/components/icons/RatingIcon";
import { User } from "@/components/User";
import { DecoratedText } from "@/components/DecoratedText";


export type PublicShareProps = {
  canonical: Pick<CanonicalContent, '_id'> | null,
  share: Pick<Share, '_id'> & {
    sender?: Pick<Contact, '_id'> | null,
    message: Pick<Message, 'text' | 'language'>,
    sendersRating?: Pick<Rating, 'rating'> | null,
  },
}

export const PublicShare: FC<PublicShareProps> = function PublicShare({ canonical, share }) {

  const history = useHistory();

  const { track } = useAnalyticsQueued();

  const href = canonicalLink(canonical?._id, share._id);

  const handleClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('click_other_share', { category: 'canonical' });
    history.push(href);
  };

  return (
    <Card
      key={share._id}
      component='a'
      href={href}
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '224px',
        flex: '0 0 auto',
        padding: 1,
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        border: `1px solid`,
        borderColor: 'grey.300'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <User
          userId={share.sender?._id}
          size={'small'}
          addLink={false}
          sx={{
            typography: {
              fontWeight: 500
            }
          }}
          postfix=' shared'
        />
        {share.sendersRating &&
          <RatingIcon value={share.sendersRating.rating} highlight />
        }
      </Box>

      <Typography
        sx={{
          mt: 0.5,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '4',
          lineClamp: '4',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          overflowWrap: 'break-word',
          maxHeight: '96px',
        }}
        variant='body1'
        component="div"
        lang={share.message.language}
      >
        <DecoratedText text={share.message.text} splitParagraphs={false} />
      </Typography>
    </Card>
  );
};
