import { FC } from 'react';
import { Smile } from 'react-feather';
import { SxProps } from '@mui/material';

import { Rating } from '../../generated/graphql';
import { DepressButton } from '../../components/DepressButton';
import { RatingIcon } from '../../components/icons/RatingIcon';


export type RateButtonProps = {
  rating: Pick<Rating, 'rating'> | null,
  setRatingPopoverOpen: (open: boolean) => void,
  sx: SxProps
}

export const RateButton: FC<RateButtonProps> = function RateButton({ rating, setRatingPopoverOpen, sx={} }) {

  return (
    <DepressButton
      isDepressed={Boolean(rating)}
      color='offWhite'
      onClick={() => setRatingPopoverOpen(true)}
      sx={{
        color: 'text.primary',
        backgroundColor: 'grey.200',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
        borderRadius: 0.5,
        fontWeight: 400,
        ...sx,
      }}
      startIcon={rating ?
        (<RatingIcon value={rating.rating} highlight width='16px' />) :
        (<Smile style={{ width: 16, height: 16, color: 'primary.main' }} />)
      }
    >
      <span>Rate</span>
    </DepressButton>
  );
}
