import { FC } from 'react';
import { Box, Divider } from '@mui/material';
import { addMonths, subMonths } from 'date-fns';

import { Comment, Contact, FeedItem as FeedItemType, List, Season, Share } from '../../generated/graphql';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { FeedItem } from './FeedItem';
import { PublicShare, PublicShareProps } from './items/PublicShare';
import { NewSeason, NewSeasonProps } from './items/NewSeason';


function dedupeByListId(feedItems: FeedItemsProps['feedItems']) {
  const map = new Map();
  feedItems.forEach(fi => {
    if(fi.format === 'added-canonical-to-list' || fi.format === 'added-to-list') {
      map.set(fi.addedToList!._id, fi);
    } else {
      map.set(fi._id, fi);
    }
  });
  return Array.from(map.values());
}


function dedupeByWatchState(feedItems: FeedItemsProps['feedItems']) {
  const map = new Map();
  feedItems.forEach(fi => {
    // only keep the "best" of full-share, added-to-watched, or added-to-watchlist
    if(fi.format === 'added-to-watched' || fi.format === 'added-to-watchlist') {
      const latest = map.get(fi.addedWatchState!.addedBy!._id);
      if(!latest || latest.format === 'added-to-watchlist') {
        map.set(fi.addedWatchState!.addedBy!._id, fi);
      } else if(fi.addedWatchState?.watchState?.comments?.length) {
        // always keep states with comments
        map.set(fi._id, fi);
      }
    } else if(fi.format === 'full-share' && fi.share?.sender) {
      const latest = map.get(fi.share.sender._id);
      if(!latest || latest.format !== 'full-share') {
        map.set(fi.share.sender._id, fi);
      } else {
        // multiple full-shares from the same person, keep all
        map.set(fi._id, fi);
      }
    } else {
      map.set(fi._id, fi);
    }
  });

  return Array.from(map.values());
}


function sortFunc(a: FeedItemsProps['feedItems'][0], b: FeedItemsProps['feedItems'][0]) {
  const oneYear = 1000*60*60*24*365;
  let aSortValue = new Date(a.createdAt).getTime();
  let bSortValue = new Date(b.createdAt).getTime();

  if(a.format === 'added-canonical-to-list' || a.format === 'added-to-list') {
    aSortValue -= oneYear;
  }
  if(b.format === 'added-canonical-to-list' || b.format === 'added-to-list') {
    bSortValue -= oneYear;
  }
  if(a.format === 'added-to-watched' || a.format === 'added-to-watchlist') {
    if(!a.addedWatchState?.watchState?.comments?.length) {
      aSortValue -= oneYear;
    } else {
      aSortValue = new Date(a.addedWatchState.watchState.comments[a.addedWatchState.watchState.comments.length-1].createdAt).getTime();
    }
  }
  if(b.format === 'added-to-watched' || b.format === 'added-to-watchlist') {
    if(!b.addedWatchState?.watchState?.comments?.length) {
      bSortValue -= oneYear;
    } else {
      bSortValue = new Date(b.addedWatchState.watchState.comments[b.addedWatchState.watchState.comments.length-1].createdAt).getTime();
    }
  }

  return bSortValue - aSortValue;
}


export type FeedItemsProps = {
  canonical: NewSeasonProps['canonical'] & PublicShareProps['canonical'] & {
    tvshow?: {
      seasons: Array<Pick<Season, 'airDate'> & NewSeasonProps['season']>,
    } | null,
  } | null,
  feedItems: Array<Pick<FeedItemType, '_id' | 'format' | 'createdAt'> & {
    share?: Pick<Share, '_id'> & {
      sender?: Pick<Contact, '_id'> | null,
    } | null,
    addedToList?: Pick<List, '_id'> | null,
    addedWatchState?: {
      addedBy?: Pick<Contact, '_id'> | null,
      watchState?: {
        comments?: Pick<Comment, '_id' | 'createdAt'>[] | null
      } | null,
    } | null,
  }>,
  publicShares: Array<Pick<Share, '_id'> & PublicShareProps['share']>,
}


export const FeedItems: FC<FeedItemsProps> = function FeedItems({ canonical, feedItems, publicShares }) {

  const seasons = canonical?.tvshow?.seasons;

  if(!feedItems && !publicShares && !seasons) {
    return null;
  }

  const filtered = feedItems.filter(fi => ['full-share', 'added-to-list', 'added-canonical-to-list', 'added-to-watched', 'added-to-watchlist'].includes(fi.format));
  const deduped1 = dedupeByListId(filtered);
  const deduped2 = dedupeByWatchState(deduped1);
  const sorted = deduped2.sort(sortFunc);

  let newSeason = null;
  if(seasons?.length) {
    newSeason = seasons.find(s => {
      const airDate = new Date(s.airDate);
      return airDate > subMonths(new Date(), 6) && airDate < addMonths(new Date(), 6);
    });
  }

  if(sorted.length === 0 && publicShares.length === 0 && !newSeason) {
    return null;
  }

  return (
    <Box sx={{ py: 2, width: '100%', overflowX: 'scroll' }}>
      <Box sx={{ px: 2, display: 'flex', flexDirection: 'row', gap: 1, width: 'fit-content', height: "140px" }}>

        {newSeason && canonical?.tvshow &&
          <NewSeason canonical={canonical} season={newSeason} />
        }

        {sorted.map((feedItem) => (
          <ErrorBoundary key={feedItem._id}>
            <FeedItem feedItem={feedItem}  />
          </ErrorBoundary>
        ))}
        {sorted.length > 0 && publicShares.length > 0 &&
          <Divider orientation="vertical" flexItem sx={{ m: 0.5 }} />
        }
        {publicShares.map(share => (
          <PublicShare key={share._id} share={share} canonical={canonical} />
        ))}
      </Box>
    </Box>
  );
}
