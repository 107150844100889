import { FC, useCallback, useMemo } from "react";
import { DeepPartial } from "@apollo/client/utilities";

import { GetCanonicalQuery, useGetCanonicalQuery, useGetShareQuery } from "../../generated/graphql";
import { useParamsDelayed } from "../../hooks/use-delayed-params";
import { useAppIsRestored } from "../../hooks/app-state";
import { useProcessUrlActions } from "../../hooks/deep-links";
import { Movie, MovieProps } from "./Movie";
import { Tvshow, TvshowProps } from "./Tvshow";
import { NotFound } from "../../components/NotFound";
import { Error as ErrorComponent } from "../../components/Error";
import { Loading } from "./Loading";


type MaybePartialCanonical = GetCanonicalQuery['getCanonical'] | DeepPartial<GetCanonicalQuery>['getCanonical'] | undefined | null;

function isFullData(canonical: MaybePartialCanonical): canonical is GetCanonicalQuery['getCanonical'] {
  return Boolean(canonical && canonical?.type !== undefined && canonical?.feedItems !== undefined);
}


export type ContainerProps = {
  isVisible: boolean
}


export const Container: FC<ContainerProps> = function({ isVisible }) {

  // Delay unset of params until after transition is complete
  const { canonicalId, shareId } = useParamsDelayed<{ canonicalId: string, shareId: string }>();

  const canonicalResult = useGetCanonicalQuery({ skip: !canonicalId, variables: { canonicalId }, returnPartialData: true, fetchPolicy: 'cache-and-network' });
  const shareResult = useGetShareQuery({ skip: !shareId, variables: { id: shareId }, returnPartialData: true, fetchPolicy: 'cache-and-network' });

  const canonical = canonicalResult.data?.getCanonical as MaybePartialCanonical;
  const isCanonicalFullyLoaded = isFullData(canonical);
  const share = shareResult.data?.getShare;

  useProcessUrlActions(share, canonicalId);

  const feedItems: Extract<GetCanonicalQuery['getCanonical'], {_id:string}>['feedItems'] = useMemo(() => {
    return (isFullData(canonical) && canonical) ? canonical.feedItems.filter(fi => fi?.share?._id !== shareId || fi.format !== 'full-share') : [];
  }, [canonical, shareId]);

  const onAppRestored = useCallback(() => {
    if(canonicalId) {
      canonicalResult.refetch({ canonicalId }).catch(err => console.error(`Error refetching canonical`, err));
    }
    if(shareId) {
      shareResult.refetch({ id: shareId }).catch(err => console.error(`Error refetching canonical share`, err));
    }
  }, [canonicalId, shareId, canonicalResult, shareResult]);
  useAppIsRestored(onAppRestored);

  if(!canonical?.type || (!canonicalId && !shareId)) {
    // Nothing to render, probably in background
    return null;
  }

  if(canonicalResult.error) {
    console.error(canonicalResult.error);
    return <ErrorComponent error={new Error(`Something went wrong fetching the page.`)} />;
  }

  if(!canonical?.type) {
    if(!canonicalResult.loading) {
      console.error('Canonical not found', JSON.stringify({ canonicalId, shareId, canonical }));
      return <NotFound />;
    }
    return <Loading />;
  }

  if(canonical.type === 'movie' && canonical.movie) {
    return (
      <Movie
        share={share || undefined}
        shareLoading={shareResult.loading}
        feedItems={isCanonicalFullyLoaded ? feedItems : []}
        shares={isCanonicalFullyLoaded ? canonical?.shares : []}
        canonical={canonical as MovieProps['canonical']}
        watchedBy={isCanonicalFullyLoaded && canonical.watchState?.watchedBy || []}
        watchlistBy={isCanonicalFullyLoaded && canonical.watchState?.watchlistBy || []}
        isVisible={isVisible}
      />
    );
  } else if(canonical.type === 'tvshow' && canonical.tvshow) {
    return (
      <Tvshow
        share={share || undefined}
        shareLoading={shareResult.loading}
        feedItems={feedItems || []}
        shares={isCanonicalFullyLoaded ? canonical.shares : []}
        canonical={canonical as TvshowProps['canonical']}
        watchedBy={isCanonicalFullyLoaded && canonical.watchState?.watchedBy || []}
        watchlistBy={isCanonicalFullyLoaded && canonical.watchState?.watchlistBy || []}
        isVisible={isVisible}
      />
    );
  } else {
    console.error('Unknown canonical type', JSON.stringify({ canonicalId, shareId, canonical, type: canonical?.type }));
    throw new Error('Unknown canonical type');
  }
}
