import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { useCanonicalExternalContentQuery } from "../../generated/graphql";
import { Loading } from "../../components/Loading";
import { ExternalContentCard } from "./ExternalContentCard";



export const ExternalContents: FC<{ canonicalId: string }> = function ExternalContents({ canonicalId }) {

  const { data, loading, error } = useCanonicalExternalContentQuery({
    variables: {
      canonicalId,
    },
  });

  if (error) {
    console.error(error);
  }

  if (loading) {
    return <Loading />;
  }

  const externalContents = data?.getCanonical?.externalContent;

  if(!externalContents?.length) {
    return null;
  }

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant='h3' sx={{ mx: 2 }}>
        Found on the web
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, my: 1, px: 2, overflow: 'scroll' }}>
        {externalContents.map(externalContent => (
          <ExternalContentCard key={externalContent._id} externalContent={externalContent} />
        ))}
      </Box>
    </Box>
  );
}
