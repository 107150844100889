import { FC } from "react";
import { useHistory } from "react-router";
import { format } from "date-fns";
import { Box, Card, Typography } from "@mui/material";
import { Tv } from "react-feather";

import { CanonicalContent, Season, TvShow } from "@/generated/graphql";


export type NewSeasonProps = {
  canonical: Pick<CanonicalContent, '_id'> & {
    tvshow?: Pick<TvShow, 'title'>,
  },
  season: Pick<Season, 'name' | 'seasonNumber' | 'airDate'>,
}

export const NewSeason: FC<NewSeasonProps> = function NewSeason({ canonical, season }) {

  const history = useHistory();

  const airDate = new Date(season.airDate);
  const now = new Date();
  const tense = (airDate > now) ? 'will premiere' : 'premiered';

  return (
    <Card
      onClick={() => {
        console.log(history.location, history.location.href);
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set("seasons-sheet", "1");
        history.push({ pathname: history.location.pathname, search: searchParams.toString() });
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '240px',
        flex: '0 0 auto',
        padding: 1,
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        border: `1px solid`,
        borderColor: 'grey.300'
      }}
    >

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ borderRadius: 999, backgroundColor: 'grey.200', color: 'text.primary', lineHeight: 0, width: '20px', height: '20px', p: '3px', pt: '2px' }}>
          <Tv style={{ width: '100%', height: '100%' }} />
        </Box>

        <Typography variant='h4'>New Season</Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ fontSize: '46px', lineHeight: '48px', fontWeight: 500, pr: 1, pt: 0.5 }}>{/* lineHeight=48px comes from 2 lines of body1 = 2*16px*1.5 */}
          {season.seasonNumber}
        </Box>

        <Typography
          sx={{
            mt: 0.5,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '4',
            lineClamp: '4',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            maxHeight: '96px',
          }}
          variant='body1'
          component="div"
          lang='en'
        >
          {season.name} of {canonical.tvshow?.title} {tense} on <span style={{ whiteSpace: 'nowrap' }}>{format(airDate, 'MMM do')}</span>.
        </Typography>
      </Box>

    </Card>
  );
}
