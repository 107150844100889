import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { Contact, Share, usePublicSharesQuery } from "../../generated/graphql";
import { MoreShare } from "../collections/collection/MoreShare";
import { ContactName } from "../../components/ContactName";


export type MoreSharesProps = {
  contact: Pick<Contact, '_id'>,
  share: Pick<Share, '_id'>,
};


export const MoreShares: FC<MoreSharesProps> = function MoreShares({ contact, share }) {

  const { data, loading, error } = usePublicSharesQuery({ variables: { userId: contact._id, limit: 6 } });

  if (error) {
    console.error(error);
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const posts = data?.profile?.posts || [];

  return (
    <Box sx={{ px: 0, pb: 1 }} component="aside">
      <Typography variant='h3' sx={{ px: 2, py: 1.5 }}>
        Latest activity from{' '}
        <ContactName contact={contact} link={true} />
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, overflowX: 'scroll', px: 2 }}>
        {posts.map(post => {
          if (post._id === share._id) {
            return null;
          }
          return <MoreShare key={post._id} share={post} canonicalContent={post.canonicalContent || undefined} />;
        })}
      </Box>
    </Box>
  );
}
