import { FC, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetShareQuery } from '../../generated/graphql';
import { canonicalLink } from '../../utils/routes';
import { useAppIsRestored } from '@/hooks/app-state';
import { useParamsDelayed } from '../../hooks/use-delayed-params';
import { Uncategorized } from './Uncategorized';
import { NotFound } from '../../components/NotFound';


export type PostContainerProps = {
  isVisible: boolean
}


export const PostContainer: FC<PostContainerProps> = memo(function PostContainer({ isVisible }: PostContainerProps) {

  // Delay unset of params until after transition is complete
  const { shareId: shareIdParam } = useParamsDelayed<{ shareId: string }>();
  const shareId = shareIdParam ? shareIdParam.split('@')[0] : shareIdParam;

  const { data, loading, error, refetch } = useGetShareQuery({ skip: !shareId, variables: { id: shareId }, returnPartialData: true, fetchPolicy: 'cache-and-network' });
  const share = data?.getShare;

  const history = useHistory();

  useEffect(() => {
    if(error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if(share?.canonicalContent && isVisible) {
      const target = canonicalLink(share.canonicalContent._id, share._id)+history.location.search;
      console.log('Redirecting from post to canonical content', { location: history.location, target });
      history.replace(target);
    }
  }, [share, isVisible, history]);

  const onAppRestored = useCallback(() => {
    if(shareId) {
      refetch({ id: shareId }).catch(err => console.error(`Error refetching canonical share`, err));
    }
  }, [shareId, refetch]);
  useAppIsRestored(onAppRestored);

  if(!shareId) {
    // Page is in background, we don't know the shareId yet
    return null;
  }

  if(!share && !loading) {
    return (<NotFound message="Post not found. You probably don't have access to show it." />);
  }

  return (
    <Uncategorized share={share || undefined} isVisible={isVisible} />
  );

});
