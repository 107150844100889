import { FC, useEffect, useRef } from "react";
import { useIsVisible } from "../../hooks/use-is-visible";
import { useScrollElement } from "../../hooks/scroll-element";


export const ScrollOnNavigation: FC<{ canonicalId?: string, shareId?: string }> = function({ canonicalId, shareId }) {
  const prevIsVisible = useRef(false);

  const isVisible = useIsVisible();
  const { scrollContainerRef } = useScrollElement();

  useEffect(() => {
    if (scrollContainerRef?.current) {
      // Instant scroll to top when navigating away from canonical
      if (!isVisible && prevIsVisible.current) {
        setTimeout(() => {
          if(scrollContainerRef?.current?.scrollTop)  {
            console.log(`Scrolling to top on new canonical or share`);
            scrollContainerRef.current.scrollTo({ top: 0, behavior: 'instant' });
          }
        }, 1000/60);
      }
      // Smooth scroll to top when navigating between canonicals
      if(isVisible && prevIsVisible.current) {
        // Use a timeout to make sure the scroll happens after the navigation
        setTimeout(() => {
          if(scrollContainerRef?.current?.scrollTop)  {
            console.log(`Scrolling to top on new canonical or share`);
            scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }, 1000/60);
      }
    }
    prevIsVisible.current = isVisible;

  }, [canonicalId, shareId, isVisible, scrollContainerRef]);

  return null;
}
