import { FC, SyntheticEvent, memo, useCallback, useMemo } from 'react';
import { BooleanParam, useQueryParam } from "use-query-params";
import { Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, useTheme } from "@mui/material";
import { ChevronRight } from "react-feather";

import { Image, Provider as ProviderType, ProviderItem } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { useNavigationActions } from '../../hooks/history';
import { useIsVisible } from '@/hooks/use-is-visible';
import { BottomSheet } from "../../components/BottomSheet";
import { Divider } from "./Divider";
import { ProviderLogo } from '../../components/ProviderLogo';
import { ProvidersSheet } from '../../components/ProvidersSheet';
import { Provider } from '../../components/Provider';


export type ProvidersProps = {
  providerItems: Array<Pick<ProviderItem, 'type'> & {
    provider: Pick<ProviderType, '_id' | 'name' | 'color'> & {
      logo?: Pick<Image, 'hash'>,
    },
    displayPriority: number,
  }>,
  providersCountry: string,
  release?: string,
};


type ProviderItemWithSelected = ProvidersProps['providerItems'][number] & {
  selected: boolean,
};


export const Providers: FC<ProvidersProps> = function Providers({ providerItems=[], release, providersCountry }) {

  const { closeSheet } = useNavigationActions();
  const { user } = useSession();
  const isVisible = useIsVisible();

  const sheetQueryParam = "providers-sheet";
  const [providersOpen, setProvidersOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const buyRentSheetQueryParam = "buy-rent-sheet";
  const [buyRentSheetOpen, setBuyRentSheetOpen] = useQueryParam(buyRentSheetQueryParam, BooleanParam);

  const hasProviders = providerItems?.length > 0;

  const { flatrateFreeProviders, buyRentProviders } = useMemo((): { flatrateFreeProviders: ProviderItemWithSelected[], buyRentProviders: ProviderItemWithSelected[] } => {
    if (!hasProviders) {
      return { flatrateFreeProviders: [], buyRentProviders: [] };
    }

    const userProviders: { _id: string }[] = user?.providers ?? [];

    const withSelected = providerItems.map((providerItem) => {
      return {
        ...providerItem,
        selected: userProviders.some(p => p._id === providerItem.provider._id),
      };
    });

    const sorted = withSelected.sort((a, b) => {
      if (a.selected && !b.selected) {
        return -1;
      }
      if (!a.selected && b.selected) {
        return 1;
      }
      if (a.type === 'free' && b.type !== 'free') {
        return -1;
      }
      if (a.type !== 'free' && b.type === 'free') {
        return 1;
      }
      return a.displayPriority - b.displayPriority;
    });

    const dedupe = (providerItem: ProviderItemWithSelected, idx: number, arr: ProviderItemWithSelected[]) => {
      return arr.findIndex(p => p.provider.name === providerItem.provider.name) === idx;
    };

    const flatrateFreeProviders = sorted.filter(providerItem => providerItem.type === 'flatrate' || providerItem.type === 'free').filter(dedupe);

    const buyRentProviders = sorted.filter(providerItem => providerItem.type === 'buy' || providerItem.type === 'rent').filter(dedupe);

    return { flatrateFreeProviders, buyRentProviders };
  }, [providerItems, hasProviders, user?.providers]);


  const onCloseProviders = useCallback((ev?: SyntheticEvent) => {
    ev?.stopPropagation();
    closeSheet(sheetQueryParam);
  }, [closeSheet, sheetQueryParam]);

  const onCloseBuyRent = useCallback((ev?: SyntheticEvent) => {
    ev?.stopPropagation();
    closeSheet(buyRentSheetQueryParam);
  }, [closeSheet, buyRentSheetQueryParam]);


  const futureRelease = !!release && Date.parse(release) > Date.now();

  return (
    <ProvidersUI
      hasProviders={hasProviders}
      flatrateFreeProviders={flatrateFreeProviders}
      buyRentProviders={buyRentProviders}
      release={release}
      futureRelease={futureRelease}
      providersOpen={!!providersOpen && isVisible}
      setProvidersOpen={setProvidersOpen}
      onCloseProviders={onCloseProviders}
      buyRentSheetOpen={!!buyRentSheetOpen && isVisible}
      setBuyRentSheetOpen={setBuyRentSheetOpen}
      onCloseBuyRent={onCloseBuyRent}
      providersCountry={providersCountry}
    />
  );
}

type ProvidersUIProps = {
  hasProviders: boolean,
  flatrateFreeProviders: ProviderItemWithSelected[],
  buyRentProviders: ProviderItemWithSelected[],
  release?: string,
  futureRelease: boolean,
  providersOpen: boolean,
  setProvidersOpen: (value: boolean) => void,
  onCloseProviders: (ev?: SyntheticEvent) => void,
  buyRentSheetOpen: boolean,
  setBuyRentSheetOpen: (value: boolean) => void,
  onCloseBuyRent: (ev?: SyntheticEvent) => void,
  providersCountry: string,
};

const ProvidersUI: FC<ProvidersUIProps> = memo(function ProvidersUI({ hasProviders, flatrateFreeProviders, buyRentProviders, release, futureRelease, providersOpen, setProvidersOpen, onCloseProviders, buyRentSheetOpen, setBuyRentSheetOpen, onCloseBuyRent, providersCountry }: ProvidersUIProps) {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ p: 2, display: 'block', width: '100%' }}>
        <Typography variant='h3'>
          Streaming on
        </Typography>
        <List disablePadding>
          {hasProviders ? (
            <>
              {flatrateFreeProviders.length > 0 &&
                <ListItem disableGutters sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {flatrateFreeProviders.map(providerItem => (
                    <Provider key={providerItem.provider._id} providerItem={providerItem} selected={providerItem.selected} />
                  ))}
                </ListItem>
              }
              {buyRentProviders.length > 0 &&
                <ListItemButton disableGutters sx={{ py: 0.5 }} onClick={() => setBuyRentSheetOpen(true)}>
                  <Typography sx={{ flex: '0 0 auto' }} variant='body1'>
                    Buy/Rent
                  </Typography>
                  <Box sx={{ pl: 3, flex: '1 1 auto', textAlign: 'right', color: 'text.secondary', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {buyRentProviders.map(providerItem => providerItem.provider.name).join(', ')}
                  </Box>
                  <Box sx={{ flex: '0 0 auto', pl: 1, lineHeight: 0 }}>
                    <ChevronRight style={{ color: theme.palette.text.secondary }} />
                  </Box>
                </ListItemButton>
              }
            </>
          ) : (
            <ListItem disableGutters sx={{ pr: '2px', color: theme.palette.text.secondary }}>
              <Typography variant='body1' noWrap sx={{ fontStyle: 'italic' }}>
                {futureRelease ? 'Coming soon' : 'Not available in your region'}
              </Typography>
            </ListItem>
          )}
          <ListItemButton disableGutters sx={{ py: 0.5 }} onClick={() => setProvidersOpen(true)}>
            <Typography sx={{ flex: '1 1 auto' }} variant='body1'>
              Manage your streaming providers
            </Typography>
            <Box sx={{ flex: '0 0 auto', pl: 1, lineHeight: 0 }}>
              <ChevronRight style={{ color: theme.palette.text.secondary }} />
            </Box>
          </ListItemButton>
        </List>
      </Box>

      {providersCountry &&
        <ProvidersSheet open={!!providersOpen} onClose={onCloseProviders} country={providersCountry} />
      }

      <BottomSheet open={!!buyRentSheetOpen} onClose={onCloseBuyRent} onOpen={() => null}>
        <List>
          <ListItem sx={{ px: 0, pb: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2">
              Buy or Rent on
            </Typography>
          </ListItem>
          {buyRentSheetOpen &&
            <>
              {buyRentProviders?.length ? (
                buyRentProviders.map(providerItem =>
                  <ListItem key={providerItem.provider.name} sx={{ px: 0 }}>
                    <ListItemAvatar>
                      {providerItem.provider.logo &&
                        <ProviderLogo name={providerItem.provider.name} logo={providerItem.provider.logo} />
                      }
                    </ListItemAvatar>
                    <ListItemText
                      primary={providerItem.provider.name}
                    />
                  </ListItem>
                )
              ) : (
                <ListItem sx={{ px: 0, mt: 1, height: 64 }}>
                  <Typography sx={{ flex: '1 1 auto', color: 'text.secondary' }} variant='body1'>
                    {(futureRelease && release) ? (
                      `First release is ${new Date(release).toLocaleDateString()}. Local release dates may vary. Please check back later.`
                    ) : (
                      'Not found on any streaming providers in your region at the moment. Please check back later.'
                    )}
                  </Typography>
                </ListItem>
              )}
              <ListItem sx={{ px: 0, pt: 4 }}>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  Powered by JustWatch
                </Typography>
              </ListItem>
            </>
          }
        </List>
      </BottomSheet>

      <Divider />
    </>
  );
});

