import { FC } from 'react';
import { Box, Typography } from "@mui/material";
import { ExternalLink } from 'react-feather';

import { Contact, Message, Share, Unfurl, WatchState } from '../../generated/graphql';
import { useIsInWatchlist, useIsWatched } from '../../hooks/watch-state';
import { useContact } from '../../hooks/use-contact';
import { useProcessUrlActions } from '../../hooks/deep-links';
import { parseInternal } from '../../utils/url';
import { truncate } from '../../utils/string';
import { Helmet } from '../../components/Helmet';
import { Layout } from "../canonical/Layout";
import { Link } from '../../components/Link';
import { Post, PostProps } from '../canonical/Post';
import { Divider } from '../canonical/Divider';
import { Activity, ActivityProps } from '../canonical/Activity';
import { ReshareButton } from '../canonical/ReshareButton';
import { MoreShares } from '../canonical/MoreShares';


export type UncategorizedProps = {
  share?: Pick<Share, '_id'> & {
    sender?: Pick<Contact, '_id'> | null,
    watchState?: Pick<WatchState, 'watchedBy' | 'watchlistBy'> | null,
    rating?: ActivityProps['rating'] | null,
    ratings?: ActivityProps['ratings'] | null,
    unfurl: Pick<Unfurl, 'title' | 'description'> & {
      images?: { hash: string }[],
    },
    message?: Pick<Message, 'text' | 'link'> | null,
  } & PostProps['share'],
  isVisible: boolean,
};


export const Uncategorized: FC<UncategorizedProps> = function Uncategorized({ share, isVisible }) {

  useProcessUrlActions(share, undefined);

  const { data: senderData } = useContact(share?.sender?._id);

  const isInWatchlist = useIsInWatchlist(share?._id, undefined);
  const isWatched = useIsWatched(share?._id, undefined);

  const watchedBy = share?.watchState?.watchedBy;
  const watchlistBy = share?.watchState?.watchlistBy;

  const rating = share?.rating;
  const ratings = share?.ratings;

  const link = parseInternal(share?.message?.link || '');

  return (
    <Layout
      title={
        <>
          <Typography variant='h2' noWrap sx={{ color: 'white', mb: '3px' }}>
            {share?.unfurl?.title}
          </Typography>
          {share?.message?.link &&
            <Link link={share.message.link}>
              <Typography variant='h4' noWrap sx={{ color: 'white', mb: '3px' }}>
                {link.isInternal &&
                  <>{link.isCollection ? 'A Collection • ' : ''}{share.unfurl?.description}</>
                }
                {!link.isInternal &&
                  <>
                    {new URL(share.message.link).hostname}
                    <ExternalLink style={{ height: '1em' }} />
                  </>
                }
              </Typography>
            </Link>
          }
        </>
      }
      headerTitle={share?.unfurl?.title || ''}
      backdrop={share?.unfurl?.images?.[0] || undefined}
      poster={undefined}
      share={share}
      watchedBy={watchedBy}
      watchlistBy={watchlistBy}
      isVisible={isVisible}
    >
      {share?.unfurl?.title && share?.message &&
        <Helmet>
          <title>{`${truncate(share.unfurl.title, 30).trim()} • Ice Cream Club`}</title>
          <meta property="og:title" content={`${truncate(share?.unfurl.title, 30).trim()} • Ice Cream Club`} />
          <meta name="author" content={senderData?.contact?.name || ''} />
          <meta name="description" content={share?.message?.text ? `${senderData?.contact?.username} shared: "${truncate(share?.message.text, 20).trim()}"` : `See what they said about "${truncate(share?.unfurl.title, 30).trim()}"`} />
          <meta property="og:description" content={share?.message?.text ? `${senderData?.contact?.username} shared: "${truncate(share?.message.text, 20).trim()}"` : `See what they said about "${truncate(share?.unfurl.title, 30).trim()}"`} />
          <meta property="og:image" content={share?.unfurl.images?.[0] ? `${process.env.REACT_APP_IMAGE_BASE}/${share?.unfurl.images[0].hash}_512x512.jpg` : "https://app.icecream.club/kawaii.png"} />
          <link rel="canonical" href={`https://app.icecream.club/post/${share?._id}/`} />
        </Helmet>
      }

      {share &&
        <Box sx={{ backgroundColor: 'background.paper' }}>
          <Post share={share} showAllComments={true} />
          <Divider sx={{ width: '100%' }} />
        </Box>
      }

      <ReshareButton share={share} />

      <Activity canonical={null} watchedBy={watchedBy || []} isWatched={isWatched} addedToWatchlistBy={watchlistBy || []} isInWatchlist={isInWatchlist} feedItems={[]} rating={rating} ratings={ratings} shares={[]} />

      {share && share?.sender?._id &&
        <MoreShares contact={share?.sender} share={share} />
      }

    </Layout>
  );
}
