import { FC, Fragment } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { Heart } from 'react-feather';

import { Contact } from '../generated/graphql';
import { ProfileLink } from './ProfileLink';
import { ContactName } from './ContactName';


export type ReactionsTextProps = {
  reactions: {
    reacted: Pick<Contact, '_id'>[],
  }[],
}


export const ReactionsText: FC<ReactionsTextProps> = function ReactionsText({ reactions }) {

  const uniqueUsersReacted = new Set<string>();
  reactions.map(({ reacted }) => reacted.map(contact => uniqueUsersReacted.add(contact._id)));
  const numberOfReactions = uniqueUsersReacted.size;
  const contactNamesLink = [...uniqueUsersReacted].map(userId => <Typography component='span' key={userId} variant="body2" fontWeight="400" noWrap><ProfileLink contactId={userId} /></Typography>);
  const contactNames = [...uniqueUsersReacted].slice(1).map(userId => [<ContactName key={userId} contact={{ _id: userId }} type='username' prefix='' />, ', ']).flat();
  contactNames.pop();

  const theme = useTheme();

  const reactionsText = [
    <Heart key={'liked-by-heart'} size={12} color='transparent' fill={theme.palette.text.secondary} style={{ marginRight: '4px', marginBottom: 1 }}/>,
    <Fragment key={'liked-by'}>&nbsp;Liked by&nbsp;</Fragment>
  ];
  if (numberOfReactions > 2) {
    const more = (
      <Tooltip key='more-tooltip' title={contactNames}>
        <Typography key="more" component="span" variant="body2" fontWeight="400">{contactNamesLink.length-1} more</Typography>
      </Tooltip>
    );
    reactionsText.push(contactNamesLink[0]);
    reactionsText.push(<Fragment key={'and'}>&nbsp;and&nbsp;</Fragment>);
    reactionsText.push(more);
  } else if (numberOfReactions === 2) {
    reactionsText.push(contactNamesLink[0]);
    reactionsText.push(<Fragment key={'and'}>&nbsp;and&nbsp;</Fragment>);
    reactionsText.push(contactNamesLink[1]);
  } else {
    reactionsText.push(contactNamesLink[0]);
  }

  return (
    <>
      {numberOfReactions > 0 &&
        <Typography component="span" variant="body2" fontWeight="400" color="text.secondary" noWrap sx={{ display: 'flex', alignItems: 'center', flex: '0 1 auto' }}>
          {reactionsText}
        </Typography>
      }
    </>
  );
}
