import { FC, SyntheticEvent, memo, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";
import { Avatar as MuiAvatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography, useTheme } from "@mui/material";
import { ChevronRight } from "react-feather";

import { Contact, Rating } from "../../generated/graphql";
import { useIsVisible } from "@/hooks/use-is-visible";
import { useSession } from "../../hooks/auth";
import { useNavigationActions } from "../../hooks/history";
import { Avatar } from "../../components/Avatar";
import { RatingIcon } from "../../components/icons/RatingIcon";
import { AvatarGroup } from "../../components/AvatarGroup";
import { BottomSheet } from "../../components/BottomSheet";
import { Button } from "../../components/Button";


type R = Pick<Rating, 'rating'> & {
  person: Pick<Contact, '_id'>,
};

export type RatedByProps = {
  rating?: R | null,
  ratings?: R[] | null,
};


export const RatedBy: FC<RatedByProps> = function RatedBy({ rating, ratings }) {

  const { user } = useSession();
  const history = useHistory();
  const isVisible = useIsVisible();

  const { closeSheet } = useNavigationActions();
  const sheetQueryParam = "rated-by-sheet";
  const [open, setOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const onClose = useCallback((ev?: SyntheticEvent) => {
    ev?.stopPropagation();
    closeSheet(sheetQueryParam);
  }, [closeSheet, sheetQueryParam]);

  const groupedRatings: [number, R[]][] = useMemo(() => {
    const ratingsMap = new Map<number, R[]>();
    for(const rating of ratings || []) {
      const ratings = ratingsMap.get(rating.rating) || [] as R[];
      ratings.push(rating);
      ratingsMap.set(rating.rating, ratings);
    }
    // Add own rating if it is not already in the list
    if(rating) {
      const sameRating = ratingsMap.get(rating.rating) || [];
      if(!sameRating.some(r => r.person._id === rating.person._id)) {
        sameRating.push(rating);
        ratingsMap.set(rating.rating, sameRating);
      }
    }
    const entries: [number, R[]][] = [1,2,3,4,5].map(value => {
      const ratings = ratingsMap.get(value) || [];
      return [ value,  ratings ];
    });

    return entries.sort((a, b) => {
      if(b[1].length - a[1].length === 0) {
        return b[0] - a[0];
      }
      return b[1].length - a[1].length;
    });
  }, [ratings, rating]);

  const handleLogin = useCallback(() => {
    history.push(`/auth/login?next=${encodeURIComponent(window.location.pathname+window.location.search)}`);
  }, [history]);

  return (
    <RatedByUI
      open={!!open && isVisible}
      setOpen={setOpen}
      onClose={onClose}
      groupedRatings={groupedRatings}
      isLoggedIn={!!user}
      handleLogin={handleLogin}
    />
  );
}


type RatedByUIProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  onClose: (ev?: SyntheticEvent) => void,
  groupedRatings: [number, R[]][],
  isLoggedIn: boolean,
  handleLogin: () => void,
}


const RatedByUI: FC<RatedByUIProps> = memo(function RatedByUI({ open, setOpen, onClose, groupedRatings, isLoggedIn, handleLogin }: RatedByUIProps) {

  const theme = useTheme();

  return (
    <>
      <ListItemButton disableGutters onClick={() => setOpen(true)}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ flex: '0 0 auto', color: 'text.primary' }}>
            Rated by
          </Typography>

          <Box sx={{ pl: 3, flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end', gap: 0.5 }}>
            {groupedRatings?.map(([value, ratings]) => (
              ratings.length > 0 && (
                <AvatarGroup key={value} max={4} size='small' sx={{ '&&.MuiAvatarGroup-root .MuiAvatar-root': { borderColor: 'grey.100' } }}>
                  <MuiAvatar sx={{ overflow: 'visible' }}>
                    <RatingIcon value={value} highlight={true} width='20px' />
                  </MuiAvatar>
                  {ratings.map((rating) => (
                    <Avatar key={rating.person._id} contact={rating.person._id} size='small'  />
                  ))}
                </AvatarGroup>
              )
            ))}
          </Box>

          <Box sx={{ flex: '0 0 auto', pl: 1, lineHeight: 0 }}>
            <ChevronRight style={{ color: theme.palette.text.secondary }} />
          </Box>
        </Box>
      </ListItemButton>

      {open &&
        <BottomSheet open={!!open} onClose={onClose} onOpen={() => null}>
          <List>
            <ListItem sx={{ px: 0, pb: 1 }}>
              <Typography variant="h2">
                Rated by
              </Typography>
            </ListItem>
            {open &&
              <>
                {!isLoggedIn &&
                  <>
                    {[0, 1].map(i => (
                      <ListItem sx={{ m:0, p:0 }} key={i}>
                        <ListItemAvatar>
                          <MuiAvatar
                            sx={{
                              color: 'text.primary',
                              fontWeight: 500,
                              backgroundColor: 'grey.300',
                              borderWidth: '2px',
                              fontSize: '20px',

                              width: '40px',
                              height: '40px',
                              borderStyle: 'none',
                              '&&&': {
                                borderWidth: '2px',
                              },
                              mr: 1,
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Skeleton variant="text" width={130} animation={false} />}
                          secondary={<Skeleton variant="text" width={60} animation={false} />}
                        />
                      </ListItem>
                    ))}
                    <ListItem sx={{ px: 0 }}>
                      <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        Log in to see how your friends have rated this.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <Button variant="contained" size='small' fullWidth onClick={handleLogin}>Log in</Button>
                    </ListItem>
                  </>
                }
                {isLoggedIn && groupedRatings.length === 0 &&
                  <ListItem sx={{ px: 0 }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Be the first among your friends to rate this.
                    </Typography>
                  </ListItem>
                }
                {isLoggedIn && groupedRatings.length > 0 &&
                  <Box sx={{ backgroundColor: 'grey.100', borderRadius: 1, px: 1 }}>
                    {groupedRatings.map(([value, ratings]) => (
                      <ListItem key={value} disableGutters sx={{ display: 'flex', gap: 1 }}>
                        <RatingIcon value={value} highlight={true} width='36px' style={{ flex: '0 0 auto' }} />

                        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                          {/* {[1,1,1,1,1,].map(() => (<> */}
                            {ratings.map((rating) => (
                              <Link key={rating.person._id} to={`/profile-id/${rating.person._id}`}>
                                <Avatar contact={rating.person._id} size='large' sx={{ width: '36px', height: '36px' }} />
                              </Link>
                            ))}
                          {/* </>))} */}
                        </Box>

                        {ratings.length > 0 &&
                          <Box sx={{ backgroundColor: 'primary.light', borderRadius: 999, px: 1, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '0 0 auto' }}>
                            <Typography variant="h3" sx={{ color: 'primary.contrastText' }}>
                              <span style={{ opacity: 0.7 }}>Rated</span> {value}<span style={{ opacity: 0.7 }}>/5</span>
                            </Typography>
                          </Box>
                        }

                      </ListItem>
                    ))}
                  </Box>
                }
              </>
            }
          </List>
        </BottomSheet>
      }

    </>
  )
});
