import { FC, Fragment, useState } from 'react';
import { Box, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Close } from '@mui/icons-material';
import ISO6391 from 'iso-639-1';

import { Season } from '../../generated/graphql';
import { canonicalLink } from '../../utils/routes';
import { createProfessionalType } from '../../utils/json-ld';
import { useNavigationActions } from '../../hooks/history';
import { useIsVisible } from '@/hooks/use-is-visible';
import { Helmet } from '../../components/Helmet';
import { GenreChip } from '../../components/GenreChip';
import { Link } from '../../components/Link';
import { Divider } from './Divider';
import { BottomSheet } from '../../components/BottomSheet';
import { Image } from '../../components/Image';


export type DetailsProps = {
  canonicalId: string,
  imdb?: string | null,
  homepage?: string | null,
  creators: { _id: string, name: string, professional?: string }[],
  directors: { _id: string, name: string, professional?: string }[],
  genres: string[],
  networks: { name: string }[],
  release?: string,
  seasons?: Season[],
  originalTitle: string,
  originalLanguage: string,
  runtimeMinutes?: number,
  title: string,
};


export const Details: FC<DetailsProps> = function Details({ canonicalId, imdb, homepage, creators, directors, genres, networks, release, seasons, originalTitle, originalLanguage, runtimeMinutes, title }) {
  const theme = useTheme();
  const history = useHistory();
  const isVisible = useIsVisible();

  const { closeSheet } = useNavigationActions();

  const [seasonDescriptionOpen, setSeasonDescriptionOpen] = useState<Season | undefined | null>();

  const sheetQueryParam = "seasons-sheet";
  const [seasonsOpen, setSeasonsOpen] = useQueryParam(sheetQueryParam, BooleanParam);

  const jsonLd = {
    "@context": "https://schema.org/",
    "@id": `https://app.icecream.club${canonicalLink(canonicalId)}`,
    "director": directors.map(createProfessionalType).filter(Boolean),
    "creator": creators.map(createProfessionalType).filter(Boolean),
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{jsonLd && JSON.stringify(jsonLd)}</script>
      </Helmet>
      <Box sx={{ p: 2, minHeight: 64, display: 'block' }}>
        <Typography variant='h3'>
          Details
        </Typography>
        <List disablePadding dense sx={{ '& > .MuiListItem-root': { px: 0 }, '& > .MuiListItemButton-root': { px: 0 }}}>

          {genres.length > 0 &&
            <ListItem sx={{ display: 'flex', gap: 1, pt: 1, flexFlow: 'wrap' }}>
              {genres.map(g =>
                <GenreChip key={g} genre={g} />
              )}
            </ListItem>
          }


          {release &&
            <ListItem sx={{ display: 'flex', gap: 1, pt: 1, flexFlow: 'wrap' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  Release
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {format(new Date(release), 'yyyy-MM-dd')}
                </Typography>
              </Box>
            </ListItem>
          }

          {seasons && seasons.length > 0 &&
            <>
              <ListItem onClick={() => setSeasonsOpen(true)}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                    Seasons
                  </Typography>
                  <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                    {seasons.length} ({format(new Date(seasons[0].airDate), 'yyyy')} - {format(new Date(seasons[seasons.length - 1].airDate), 'yyyy')}), {seasons.reduce((acc, curr) => acc + (curr.episodeCount || 0), 0)} episodes
                  </Typography>
                </Box>
              </ListItem>

              <BottomSheet
                variant='modal'
                onClose={() => closeSheet(sheetQueryParam)}
                onOpen={() => null}
                open={Boolean(seasonsOpen) && isVisible}
                disablePadding={true}
              >
                <Typography variant='h3' sx={{ px: 2, pb: 1 }}>
                  Seasons
                </Typography>
                <List sx={{ pt: 0, pb: 2 }}>
                  {seasons.map(s => (
                    <ListItem key={s.seasonNumber} sx={{ py: 0.5 }} onClick={() => setSeasonDescriptionOpen(s)}>
                      <ListItemIcon>
                        {s.poster &&
                          <Image constraints={`${56*2}x${76*2}_c`} {...s.poster} width={56} height={76} />
                        }
                      </ListItemIcon>
                      <ListItemText
                        sx={{ pl: 1 }}
                        primary={s.name}
                        secondary={
                          <Box component='span' sx={{ overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, lineClamp: 2, textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>
                            {s.episodeCount} episodes - {new Date(s.airDate) < new Date() ? 'Premiered' : 'Premiers'} {format(new Date(s.airDate), 'yyyy-MM-dd')}
                            <br />
                            {s.overview}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </BottomSheet>
              {seasonDescriptionOpen && isVisible &&
                <Modal onClick={() => setSeasonDescriptionOpen(undefined)} open={true}>
                  <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'background.paper', height: '100%', px: 0, pb: 4, pt: 'var(--sat)', overflowX: 'scroll', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', right: 0, top: 'var(--sat)', m: 1.5, cursor: 'pointer', zIndex: 1, backgroundColor: alpha(theme.palette.grey[300], 0.7), borderRadius: '50%', lineHeight: 0, p: '5px' }}>
                      <Close onClick={() => setSeasonDescriptionOpen(undefined)} style={{ color: theme.palette.grey[800] }} />
                    </Box>

                    {seasonDescriptionOpen.poster &&
                      <Image constraints="800x800" {...seasonDescriptionOpen.poster} alt={seasonDescriptionOpen.poster.alt || `${seasonDescriptionOpen.name} poster`}  />
                    }
                    <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Typography variant='h2' sx={{ mt: 1.5, mb: 0.5 }}>
                        {seasonDescriptionOpen.name}
                      </Typography>
                      <Typography variant='body1'>
                        {new Date(seasonDescriptionOpen.airDate) < new Date() ? 'Premiered' : 'Will premier'} on {format(new Date(seasonDescriptionOpen.airDate), 'yyyy-MM-dd')}
                      </Typography>
                      <Typography variant='body1'>
                        {seasonDescriptionOpen.episodeCount} episodes
                      </Typography>
                      <Typography variant='body1'>
                        {seasonDescriptionOpen.overview}
                      </Typography>
                    </Box>
                  </Container>
                </Modal>
              }
            </>
          }

          {networks.length > 0 &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  {networks.length > 1 ? 'Networks' : 'Network'}
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {networks.map(d => d.name).join(', ')}
                </Typography>
              </Box>
            </ListItem>
          }

          {creators.length > 0 &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  {creators.length > 1 ? 'Creators' : 'Creator'}
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {creators.map((creator, i) => {
                    if(creator.professional) {
                      return (
                        <Fragment key={creator._id}>
                          {i > 0 && ', '}
                          <a href={`/professional/${creator.professional}`} onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); history.push(`/professional/${creator.professional}`); }}>
                            {creator.name}
                          </a>
                        </Fragment>
                      );
                    }
                    return <Fragment key={creator._id}>{i > 0 && ', '}{creator.name}</Fragment>;
                  })}
                </Typography>
              </Box>
            </ListItem>
          }

          {directors.length > 0 &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  {directors.length > 1 ? 'Directors' : 'Director'}
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {directors.map((director, i) => {
                    if(director.professional) {
                      return (
                        <Fragment key={director._id}>
                          {i > 0 && ', '}
                          <a href={`/professional/${director.professional}`} onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); history.push(`/professional/${director.professional}`); }}>
                            {director.name}
                          </a>
                        </Fragment>
                      );
                    }
                    return <Fragment key={director._id}>{i > 0 && ', '}{director.name}</Fragment>;
                  })}
                </Typography>
              </Box>
            </ListItem>
          }

          {originalTitle && originalTitle !== title &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  Original title
                </Typography>
                <Tooltip title={originalTitle} arrow>
                  <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                    {originalTitle}
                  </Typography>
                </Tooltip>
              </Box>
            </ListItem>
          }

          {originalLanguage &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  Original language
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {ISO6391.getName(originalLanguage)}
                </Typography>
              </Box>
            </ListItem>
          }

          {runtimeMinutes && runtimeMinutes > 0 &&
            <ListItem>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  Runtime
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {runtimeMinutes > 60 &&
                    <>
                      {Math.floor(runtimeMinutes / 60)} hour
                      {' '}
                    </>
                  }
                  <>
                    {runtimeMinutes % 60} minutes
                  </>
                </Typography>
              </Box>
            </ListItem>
          }

          {imdb &&
            <ListItemButton component='li'>
              <Link link={`https://www.imdb.com/title/${imdb}/`} sx={{ display: 'flex', width: '100%', color: 'inherit' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  IMDb
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  imdb.com/title/{imdb}/
                </Typography>
              </Link>
            </ListItemButton>
          }

          {homepage &&
            <ListItemButton component='li'>
              <Link link={homepage} sx={{ display: 'flex', width: '100%', color: 'inherit' }}>
                <Typography variant='body1' sx={{ flex: '0 0 auto' }}>
                  Homepage
                </Typography>
                <Typography variant='body1' sx={{ pl: 3, flex: '1 1 auto', color: theme.palette.text.secondary, textAlign: 'right' }} noWrap>
                  {homepage.replace(/https?:\/\/(www\.)?/, '')}
                </Typography>
              </Link>
            </ListItemButton>
          }
        </List>
      </Box>
      <Divider />
    </>
  );
}
