import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { useGetRecommendationsQuery } from '../../generated/graphql';
import { PosterCard } from '../../components/PosterCard';


export const OtherCanonicals: FC<{ canonicalId: string }> = function OtherCanonicals({ canonicalId }) {

  const { data, error } = useGetRecommendationsQuery({ variables: { canonicalId: canonicalId } });

  if(error) {
    console.error('Error fetching recommendations for canonical', error);
  }

  const allRecommendations = data?.getCanonical?.recommendations || [];
  const recommendations = allRecommendations.filter(r => Boolean(r.movie?.poster || r.tvshow?.poster));

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography variant='h3' sx={{ mx: 2 }}>
          More like this
        </Typography>
        <Box sx={{ display: 'flex', my: 1, px: 2, gap: 2, overflow: 'scroll' }}>
          {recommendations.length > 0 &&
            recommendations.map(recommendation => (
              <PosterCard key={recommendation._id} canonicalContent={recommendation} sx={{ flex: '0 0 auto' }} width={136} height={200} loading='lazy' showTitle={true} trackingCategory='other-canonicals' />
            ))
          }
          {!(recommendations.length) &&
            [1, 2, 3].map(i =>
              <Box
                key={i}
                sx={{ flex: '0 0 auto', nr: 1, width: 136, height: 200, display: 'flex', borderWidth: 2, borderStyle: 'dashed', borderRadius: '4px', borderColor: 'grey.300', backgroundColor: 'grey.50', justifyContent: 'center', alignItems: 'center' }}
              />
            )
          }
        </Box>
      </Box>
    </>
  );
}
