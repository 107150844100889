import { FC, useEffect, useState } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useHistory } from "react-router";

import { useGetCanonicalCastQuery } from "../../generated/graphql";
import { createPerformanceType } from "../../utils/json-ld";
import { useScrollElement } from "../../hooks/scroll-element";
import { LazyRender } from "../../components/LazyRender";
import { Helmet } from '../../components/Helmet';
import { Divider } from "./Divider";
import { Button } from "../../components/Button";


export type CastProps = {
  canonicalId: string,
};


export const Cast: FC<CastProps> = function Cast({ canonicalId }) {

  const history = useHistory();
  const [expanded, setExpanded] = useState(3);

  const { scrollContainerRef } = useScrollElement();

  useEffect(() => {
    const unlisten = history.listen(() => {
      setExpanded(3);
    });
    return unlisten;
  }, [history]);

  const { data, loading, error } = useGetCanonicalCastQuery({ variables: { canonicalId } });

  if(error) {
    console.error(error);
  }

  const cast = data?.getCanonical?.tvshow?.cast || data?.getCanonical?.movie?.cast || [];

  if(!loading && cast.length === 0) {
    return null;
  }

  const jsonLd = createPerformanceType(cast, canonicalId);

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>{jsonLd && JSON.stringify(jsonLd)}</script>
      </Helmet>
      <Box sx={{ p: 2, display: 'block' }}>
        <Typography variant='h3' sx={{ mb: 1 }}>Cast</Typography>
        <List dense disablePadding>
          {loading && "Loading..."}
          {cast.slice(0, expanded).map(c => (
            <li key={c._id} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
              <LazyRender key={c._id} height={32} offset={2000} resize={true} unmountIfInvisible={true} overflow={true} scrollContainerRef={scrollContainerRef}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                  <Typography component='span' noWrap variant='body1' color='text.primary' sx={{ pr: 1.5, flex: '0 1 auto' }}>
                    {c.professional &&
                      <a href={`/professional/${c.professional}`} onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); history.push(`/professional/${c.professional}`) }}>{c.name}</a>
                    }
                    {!c.professional &&
                      c.name
                    }
                  </Typography>

                  <Typography component='span' noWrap variant='body1' color='text.secondary' sx={{ flex: '0 10000 auto', minWidth: '30%', textAlign: 'right' }}>
                    {c.character}
                  </Typography>

                </Box>
              </LazyRender>
            </li>
          ))}
          {expanded < cast.length &&
            <ListItem disableGutters onClick={() => setExpanded(expanded + 100)}>
              <Button variant='text' sx={{ fontStyle: 'italic', color: 'text.secondary', p: 0 }}>And {cast.length - expanded} more...</Button>
            </ListItem>
          }
        </List>
      </Box>

      <Divider />
    </>
  );
}
