import { FC } from 'react';
import { Box } from '@mui/material';

import { Video } from '../../generated/graphql';
import { AutoplayingVideo } from '@/components/AutoplayingVideo';


export type TrailerProps = {
  video?: Video,
}


export const Trailer: FC<TrailerProps> = function Trailer({ video }) {

  let videoUrl = null;
  if(video?.site === 'YouTube') {
    videoUrl = `https://www.youtube.com/watch?v=${video.key}`;
  } else if(video?.site === 'Vimeo') {
    videoUrl = `https://vimeo.com/${video.key}`;
  }

  if(!videoUrl) {
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <AutoplayingVideo url={videoUrl} sx={{ mt: 3 }} />
    </Box>
  );
}
