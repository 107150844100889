import { FC } from "react";
import ISO6391 from 'iso-639-1';
import { Box } from "@mui/material";

import { CanonicalContent, Movie, TvShow } from "../../generated/graphql";
import { LinkBox } from "@/components/LinkBox";


export type SiteindexLinksProps = {
  canonicalContent: Pick<CanonicalContent, '_id' | 'type'> & {
    movie?: Pick<Movie, 'year' | 'genres' | 'originalLanguage'> | null,
    tvshow?: Pick<TvShow, 'startYear' | 'genres' | 'originalLanguage'> | null,
  },
};


export const SiteindexLinks: FC<SiteindexLinksProps> = function SiteindexLinks({ canonicalContent }) {

  const type = canonicalContent.type;
  const types = type === 'movie' ? 'movies' : 'tvshows';
  const content = { ...canonicalContent.movie, ...canonicalContent.tvshow };
  const year = canonicalContent.type === 'movie' ? content.year : content.startYear;
  const genre = content.genres?.[0];
  const language = content.originalLanguage;

  return (
    <Box sx={{ px: 2, my: 4, display: 'flex', gap: 1, flexDirection: 'column' }}>

      {year &&
        <LinkBox href={`/siteindex/${types}/by-year/${year}/`} sx={{ typography: 'body1', textDecoration: 'underline' }}>
          Find more {types} from {year}.
        </LinkBox>
      }

      {language &&
        <LinkBox href={`/siteindex/${types}/by-language/${language}/`} sx={{ typography: 'body1', textDecoration: 'underline' }}>
          Find more {types} in {ISO6391.getName(language).toLowerCase()}.
        </LinkBox>
      }

      {genre &&
        <LinkBox href={`/siteindex/${types}/by-genre/${genre}/`} sx={{ typography: 'body1', textDecoration: 'underline' }}>
          Find more {genre.toLowerCase()} {types}.
        </LinkBox>
      }

      <LinkBox href={`/siteindex/${types}/`} sx={{ typography: 'body1', textDecoration: 'underline' }}>
        Find more {types}.
      </LinkBox>

    </Box>
  );
}
