import PropTypes from 'prop-types';

import { AddedToList } from './items/AddedToList';
import { FullShare } from './items/FullShare';
import { AddedWatchState } from './items/AddedWatchState';


export function FeedItem({ feedItem }) {

  let format = feedItem.format;
  if (format === 'full-share' && feedItem.share?.reshareOf) {
    format = 'reshare';
  }

  switch(format) {
    case 'started-following':
      // started-following should not be visible in feed
      //return <StartedFollowing {...feedItem.startedFollowing} />;
      return null;
    case 'added-to-group':
      // added-to-group should not be visible
      //return <AddedToGroup {...feedItem.addedToGroup} />;
      return null;
    case 'added-to-watchlist':
      return <AddedWatchState watchState={feedItem.addedWatchState.watchState} addedBy={feedItem.addedWatchState.addedBy} canonicalContent={feedItem.canonicalContent} share={feedItem.share} state='watchlist' />;
    case 'added-to-watched':
      return <AddedWatchState watchState={feedItem.addedWatchState.watchState} addedBy={feedItem.addedWatchState.addedBy} canonicalContent={feedItem.canonicalContent} share={feedItem.share} state='watched' />;
    case 'watch-state-comment':
      return null;
    case 'added-to-list':
      // fall through
    case 'added-canonical-to-list':
      return <AddedToList list={feedItem.addedToList} addedBy={feedItem.addedToListBy} />;
    case 'reshare':
      return <FullShare share={feedItem.share} postfix="reshared" />;
    case 'full-share':
      return <FullShare share={feedItem.share} />;
    case 'new-comment':
      // return <NewComment feedItem={feedItem} />
      return null;
    case 'new-rating':
      return null;
    default:
      // We use "soft error" here so old app versions dont crash if getting new data format from server.
      console.error(`Don't know how to render feed item format ${feedItem.format}`);
      return null;
  }
}

FeedItem.propTypes = {
  feedItem: PropTypes.object.isRequired,
}
