import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { CanonicalContent, FeedItem, Share } from "../../generated/graphql";
import { WatchedBy } from "./WatchedBy";
import { AddedToWatchlistBy } from "./AddedToWatchlistBy";
import { FeedItems, FeedItemsProps } from "./FeedItems";
import { RatedBy, RatedByProps } from "./RatedBy";
import { Divider } from "./Divider";


export type ActivityProps = {
  canonical: Pick<CanonicalContent, '_id'> & FeedItemsProps['canonical'] | null,
  share?: Pick<Share, '_id'> | null,
  watchedBy: string[],
  isWatched: number,
  addedToWatchlistBy: string[],
  isInWatchlist: boolean,
  feedItems: Array<Pick<FeedItem, '_id' | 'format'> & {
    share?: Pick<Share, '_id'> | null,
  }> & FeedItemsProps['feedItems'],
  shares: Array<Pick<Share, '_id'> & FeedItemsProps['publicShares'][number]>,
  rating?: RatedByProps['rating'],
  ratings?: RatedByProps['ratings'],
};


export const Activity: FC<ActivityProps> = function Activity({ canonical, share, watchedBy, isWatched, addedToWatchlistBy, isInWatchlist, feedItems, rating, ratings, shares }) {

  // remove public shares that are already in the feed
  const dedupedShares = shares?.filter(s => !feedItems.some(feedItem => feedItem.format === 'full-share' && feedItem.share?._id === s._id) && s._id !== share?._id) || [];

  return (
    <>
      <Box sx={{ py: 2, display: 'block' }}>
        <Typography variant='h3' sx={{ px: 2 }}>Activity</Typography>
        <FeedItems canonical={canonical} feedItems={feedItems} publicShares={dedupedShares} />

        <Box sx={{ px: 2, color: 'text.secondary' }}>
          <WatchedBy watchedBy={watchedBy} isWatched={isWatched} />

          <AddedToWatchlistBy addedToWatchlistBy={addedToWatchlistBy} isInWatchlist={isInWatchlist} />

          <RatedBy rating={rating} ratings={ratings} />
        </Box>
      </Box>
      <Divider />
    </>
  );
}
