import { FC, SyntheticEvent } from 'react';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useContact } from '../../hooks/use-contact';
import { useSession } from '../../hooks/auth';
import { Avatar } from '../../components/Avatar';


export const UserListItem: FC<{ userId: string }> = function UserListItem({ userId }) {

  const history = useHistory();
  const { user: session } = useSession();
  const { data, loading, error } = useContact(userId);

  if(loading) {
    return null;
  }

  if(error) {
    console.error("Error when resolving contact in UserListItem", { userId, error });
    return null;
  }

  const user = data?.contact;

  if(!user) {
    console.error("Couldn't resolve contact in UserListItem", { userId, data });
    return null;
  }

  const handleClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(`/@${user.username}`);
  };

  return (
    <ListItem onClick={handleClick} sx={{ m:0, p:0 }}>
      <ListItemAvatar>
        <Avatar
          sx={{ mr: 1 }}
          size='large'
          contact={user}
        />
      </ListItemAvatar>
      <ListItemText
        primary={session && user._id === session?._id ? 'Me' : user.name}
        secondary={'@' + user.username}
      />
    </ListItem>
  );
}
