import { FC } from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";

import { ExternalContent, Image, Unfurl } from "../../generated/graphql";
import { useOpenLink } from "../../hooks/link";


export type ExternalContentCardProps = {
  externalContent: Pick<ExternalContent, '_id' | 'url' | 'siteName' | 'author' | 'snippet'> & {
    unfurl?: Pick<Unfurl, | 'author'> & {
      favicon?: Pick<Image, 'hash'> | null
    } | null,
  }
};


export const ExternalContentCard: FC<ExternalContentCardProps> = function ExternalContentCard({ externalContent }) {

  const { openLink } = useOpenLink(externalContent.url);

  return (
    <Card
      component="a"
      href={externalContent.url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '240px',
        flex: '0 0 auto',
        padding: 1,
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        border: `1px solid`,
        borderColor: 'grey.300'
      }}
      onClick={openLink}
    >

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar
          alt={''}
          src={externalContent.unfurl?.favicon ? `${process.env.REACT_APP_IMAGE_BASE}/${externalContent.unfurl.favicon.hash}_128x128_c.jpg` : undefined}
          imgProps={{ loading: 'lazy' }}
          component='span'
          sx={{
            color: 'text.primary',
            fontWeight: 500,
            backgroundColor: 'grey.300',
            width: '20px',
            height: '20px',
            fontSize: '12px',
            borderWidth: '1.5px',

            borderStyle: 'none',
            '&&&': {
              borderWidth: '1.5px',
            },
            '&&& span.MuiAvatar-colorDefault, &&& div.MuiAvatar-colorDefault': {
              width: '20px',
              height: '20px',
            },
          }}
        />
        <Typography variant='h4'>
          {externalContent.siteName || externalContent.author || externalContent.unfurl?.author}
        </Typography>
      </Box>

      <Typography
        sx={{
          mt: 0.5,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '4',
          lineClamp: '4',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          overflowWrap: 'break-word',
          maxHeight: '96px',
        }}
        variant='body1'
        component="div"
        lang='en'
      >
        {externalContent.snippet}
      </Typography>

      <Typography variant='body2' noWrap sx={{ color: 'text.secondary' }}>
        {externalContent.url.replace(/https?:\/\/(www\.)?/, '')}
      </Typography>

    </Card>
  );
}
