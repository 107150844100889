import PropTypes from 'prop-types';
import { Box, Typography } from "@mui/material";
import { Imdb as ImdbLogo } from '../../components/icons/Imdb';
import { Link } from '../../components/Link';


export function Imdb({ imdbId, rating, sx }) {
  return (
    <Box sx={{ display: 'flex', ...sx }} component={Link} link={`https://www.imdb.com/title/${imdbId}/`} title="To IMDb">
      <ImdbLogo style={{ height: 18, width: 'auto' }} />
      <Typography variant='button' component='div' sx={{ fontSize: 14, fontWeight: 400, lineHeight: '18px', ml: '3px' }}>
        {Number(rating).toFixed(1)}
      </Typography>
    </Box>
  );
}

Imdb.propTypes = {
  imdbId: PropTypes.string.isRequired,
  rating: PropTypes.number,
  sx: PropTypes.object
};
