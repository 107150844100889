import { FC, useMemo } from 'react';
import { Box } from "@mui/material";

import { useSession } from '../../hooks/auth';
import { AvatarList } from '../../components/AvatarList';


interface WatchStateProps {
  watchlistBy: string[],
  isInWatchlist: boolean,
  watchedBy: string[],
  isWatched: number,
}

export const WatchState: FC<WatchStateProps> = function WatchState({ watchlistBy, isInWatchlist, watchedBy, isWatched }) {

  const { user } = useSession();

  const watchStates = useMemo(() => {
    return [...watchedBy, ...watchlistBy, isInWatchlist && user?._id, (isWatched > 0) && user?._id].filter((contactId, index, arr) => contactId && arr.indexOf(contactId) === index) as string[];
  }, [watchedBy, watchlistBy, isInWatchlist, isWatched, user]);

  return (
    <>
      <Box sx={{
        position: 'absolute',
        top: 0,
        right: 16,
        height: '56px',
        zIndex: 4,
        display: 'flex',
        alignItems: 'center',
      }}>
        {watchStates.length > 0 &&
          <Box sx={{ borderRadius: '999px', backdropFilter: 'blur(3px) contrast(0.7)', p: 0.5, px: 0.5, backgroundColor: 'rgba(255, 255, 255, 0.3)', lineHeight: 0 }}>
            <AvatarList contacts={watchStates} size="small" sx={{ borderWidth: '1.5px', pl: 0 }} />
          </Box>
        }
      </Box>
    </>
  );
}
