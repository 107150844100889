import { FC, MouseEvent } from 'react';
import { useAnalytics } from 'use-analytics';
import { SxProps } from '@mui/system';

import { useSession } from '../../hooks/auth';
import { useToggleInWatchlist } from '../../hooks/watch-state';
import { useLoginPush } from '../../hooks/login-push';
import { AddCircle, AddCircleFilled } from '../../components/icons';
import { DepressButton } from '../../components/DepressButton';



export type WatchlistButtonProps = {
  canonicalId?: string,
  shareId?: string,
  isInWatchlist: boolean,
  sx?: SxProps,
}


export const WatchlistButton: FC<WatchlistButtonProps> = function WatchlistButton({ canonicalId, shareId, isInWatchlist, sx={} }) {

  const { track } = useAnalytics();
  const { user } = useSession();
  const { setLoginHint } = useLoginPush();

  const toggleWatchlist = useToggleInWatchlist();

  const onClick = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      track('toggle_watchlist_button', { category: 'watch-state', origin: 'canonical' });
      toggleWatchlist(canonicalId, shareId, !isInWatchlist);
    } else {
      track('click_watchlist_login', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to keep track of what you want to see.');
    }
  };

  return (
    <DepressButton
      isDepressed={isInWatchlist}
      color='offWhite'
      onClick={onClick}
      sx={{
        color: 'text.primary',
        backgroundColor: 'grey.200',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
        borderRadius: 0.5,
        fontWeight: 400,
        ...sx,
      }}
      startIcon={isInWatchlist ?
        (<AddCircleFilled sx={{ width: 16, height: 16, color: 'primary.main' }} />) :
        (<AddCircle sx={{ width: 16, height: 16, color: 'text.primary', strokeWidth: 2 }} />)
      }
    >
      Watchlist
    </DepressButton>
  );
}
