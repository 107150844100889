import { FC, MouseEvent, useState } from 'react';
import { Button, SxProps } from "@mui/material";
import { MoreHoriz } from '@mui/icons-material';

import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { Dialog, DialogProps } from '../../components/unfurl/Dialog';


export type OptionsButtonProps = {
  share: DialogProps['share']
  canonical: DialogProps['canonicalContent'],
  sx?: SxProps,
}


export const OptionsButton: FC<OptionsButtonProps> = function OptionsButton({ share, canonical, sx={}, ...rest }) {

  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if(user) {
      track('open_options_dialog', { category: 'canonical' });
      setDialogOpen(true)
    } else {
      track('click_toggle_watched', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save this.');
    }
  }

  return (
    <>
      <Button
        title='More options'
        color='offWhite'
        variant='text'
        size="small"
        onClick={handleClick}
        sx={{
          color: 'text.primary',
          fontWeight: 400,
          minWidth: 0,
          ...sx,
        }}
        {...rest}
      >
        <MoreHoriz />
      </Button>

      {dialogOpen &&
        <Dialog share={share} canonicalContent={canonical} open={dialogOpen} setOpen={setDialogOpen} />
      }
    </>
  );
}

