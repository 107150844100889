import { FC, ReactNode } from 'react';
import { Box, Card, Typography, useTheme, SxProps } from '@mui/material';
import { ExternalLink } from 'react-feather';

import { FallbackImage } from '../FallbackImage';
import { domain } from '../../utils/url';


export type ExternalProps = {
  title?: string,
  image?: ReactNode,
  link: string,
  sx: SxProps,
}


export const External: FC<ExternalProps> = function External({ title, image, link, sx }) {

  const theme = useTheme();

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 64, borderRadius: 1, border: `1px solid ${theme.palette.grey['300']}`, ...sx }}>
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>

        <Box sx={{ width: 64, flex: '0 0 auto' }}>
          {image || (
            <FallbackImage color={theme.palette.primary.light} scale={2} style={{ height: '100%' }} />
          )}
        </Box>

        <Box
          sx={{
            px: 1,
            py: 1,
            flex: '1 1 auto',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>

          <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.primary', mb: 0.5 }}>
            <Typography variant="body1" fontStyle={title ? 'normal' : 'italic'} color="textPrimary" noWrap>
              {title || 'Title missing'}
            </Typography>
          </Box>

          <Typography variant="body2" color="textSecondary" noWrap>
            {domain(link)}
          </Typography>

        </Box>
      </Box>

      <ExternalLink style={{ color: theme.palette.grey[600], alignSelf: 'center', marginRight: 16, width: 20, flex: '0 0 auto' }} />

    </Card>
  );
}
