import { FC, MouseEvent, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, useTheme } from '@mui/material';

import { Contact, Image as ImageType, List } from '@/generated/graphql';
import { useContact } from '@/hooks/use-contact';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { User } from '@/components/User';
import { Image } from '@/components/Image';


export type AddedToListProps = {
  list: Pick<List, '_id' | 'name'> & {
    images: Pick<ImageType, 'hash' | 'alt' | 'width' | 'height'>[],
  },
  addedBy: Pick<Contact, '_id'>,
}


export const AddedToList: FC<AddedToListProps> = memo(function AddedToList({ list, addedBy, ...rest }: AddedToListProps) {
  const history = useHistory();
  const { track } = useAnalyticsQueued();
  const theme = useTheme();

  const { data } = useContact(addedBy._id);
  const addedByUser = data?.contact;

  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('click_canonical_addedtolist', { category: 'canonical' });
    history.push(`/@${addedByUser?.username}/collections/${list._id}`);
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '224px',
        flex: '0 0 auto',
        padding: 1,
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        border: `1px solid`,
        borderColor: 'grey.300'
      }}
      onClick={handleClick}
      {...rest}
    >

      <User
        userId={addedBy._id}
        size={'small'}
        addLink={false}
        sx={{
          overflow: 'hidden',
          typography: {
            whiteSpace: 'nowrap',
          }
        }}
        postfix={
          <Box sx={{ display: 'inline', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {' '}saved <span style={{ color: theme.palette.text.secondary }}> {list.name}</span>
          </Box>
        }
      />

      <Box sx={{
        backgroundColor: 'grey.100',
        borderColor: 'grey.300',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 0.5,
        p: 0.5,
        display: 'flex',
        gap: 1,
        mt: 1,
        overflow: 'hidden',
        width: 'fit-content'
      }}>
        {list.images.map((image, i) => (
          <Box key={i} sx={{ width: 40, height: 60, borderRadius: 0.5, overflow: 'hidden', flex: '0 0 auto' }}>
            <Image
              {...image}
              constraints={`${80}x${120}_c`}
              width={40}
              height={60}
            />
          </Box>
        ))}
      </Box>
    </Card>
  );
});
